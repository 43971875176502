import styled from '@emotion/styled';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';
import React, { CSSProperties } from 'react';

type ComponentKey = 'Content' | 'Header' | 'Subtitle' | 'Title';
type CardComponentList = { [key in ComponentKey]: React.ComponentType<any> };

const defaultComponents: CardComponentList = {
  Content: IonCardContent,
  Header: IonCardHeader,
  Subtitle: IonCardSubtitle,
  Title: IonCardTitle,
};

type Props = {
  bg?: string;
  bgStyle?: React.CSSProperties;
  className?: string;
  components?: CardComponentList;
  footer?: React.ReactNode;
  onClick?(): void;
  style?: React.CSSProperties;
  subtitle?: React.ReactNode;
  textColor?: 'dark' | 'light';
  title?: React.ReactNode;
  // FIXME any
  titleProps?: any;
};

type BackgroundProps = {
  bg?: string;
  textColor?: 'dark' | 'light';
};

const Background = styled.div<BackgroundProps>`
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)),
    var(--bg);
  background-position: 50%;
  background-size: cover;
  color: ${(p) => (p.textColor === 'light' ? '#fff' : '#000')};
  height: 100%;
`;

export const Card: React.FC<Props> = ({
  bg,
  bgStyle,
  children,
  className,
  components = {},
  footer,
  onClick = () => {},
  style,
  subtitle,
  textColor = 'dark',
  title,
  titleProps,
}) => {
  const { Content, Header, Subtitle, Title } = {
    ...defaultComponents,
    ...components,
  };
  return (
    <IonCard className={className} mode="ios" onClick={onClick} style={style}>
      <Background
        style={{ '--bg': `url(${bg})`, ...bgStyle } as CSSProperties}
        textColor={textColor}
      >
        {(subtitle || title) && (
          <Header>
            {subtitle && <Subtitle color={textColor}>{subtitle}</Subtitle>}
            {title && (
              <Title color={textColor} {...titleProps}>
                {title}
              </Title>
            )}
          </Header>
        )}
        {children && <Content>{children}</Content>}
      </Background>
      {footer}
    </IonCard>
  );
};
