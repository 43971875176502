import React, { useState } from 'react';
import {
  IonButton,
  IonModal,
  IonDatetime,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import moment from 'moment';
import './MyDatePicker.css';

type Props = {
  onChange: (date: string) => void;
  value: string;
};
const MyDatePicker: React.FC<Props> = ({ onChange, value }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>(value);

  return (
    <>
      <IonButton
        fill="clear"
        onClick={() => setShowModal(true)}
        style={{
          color: 'var(--color-secondary-800)',
          fontSize: '1rem',
          width: '100%',
        }}
      >
        {value}
      </IonButton>
      <IonModal
        style={{
          '--width': 'auto',
          '--height': 'auto',
        }}
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        className="custom-modal"
      >
        <IonHeader>
          <IonToolbar>
            <IonButton
              color={'light'}
              slot="end"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </IonButton>
            <IonButton
              color={'primary'}
              slot="end"
              onClick={() => {
                onChange(moment(selectedDate).format('YYYY-MM-DD'));
                setShowModal(false);
              }}
            >
              Done
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonDatetime
          min="2020"
          max="2030"
          presentation="date"
          preferWheel={true}
          onIonChange={(e) => {
            setSelectedDate(e.detail.value as string);
          }}
          id="date-picker"
        />
      </IonModal>
    </>
  );
};

export default MyDatePicker;
