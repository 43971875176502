import {
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonToggle,
} from '@ionic/react';
import _, { map } from 'lodash';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from './Button/Button';
import { GroupContext, Group } from '../context/GroupContext';
import { UserContext } from '../context/UserContext';
import { useActiveRoute } from '../hooks/useActiveRoute';
import { Route, RouteContext } from '../context/RouteContext';

type onSaveParams = {
  updatedGroup?: Group;
};

type Props = {
  group?: Group;
  onDismiss(): void;
  onSave(params?: onSaveParams): void;
};

export const GroupForm = ({ group, onDismiss, onSave }: Props) => {
  const { createGroup, updateGroup } = useContext(GroupContext);
  const { user, setUser } = useContext(UserContext);
  const { getById, routes } = useContext(RouteContext);
  const { route } = useActiveRoute();

  const [isPublic, setIsPublic] = useState(group ? group.public : true);
  const [name, setName] = useState(group ? group.name : '');
  const [selectedRoute, setSelectedRoute] = useState<Route>(route);
  const { t } = useTranslation();

  const handleChange = (e: any) => {
    setName(e.target.value);
  };
  const handleRouteChange = (e: any) => {
    setSelectedRoute(getById(e.target.value));
  };

  const handleSubmit = async () => {
    const groupOptions = {
      name,
      public: isPublic,
      route_id: selectedRoute.id,
    };

    if (group) {
      const updatedGroup = await updateGroup(group!.secret, groupOptions);

      const filteredGroups = _.filter(
        [...user.groups],
        (g) => g.secret !== group!.secret
      );
      const params: onSaveParams = {
        updatedGroup,
      };
      setUser({
        groups: [...filteredGroups, updatedGroup],
      });
      setName(updatedGroup.name);
      setIsPublic(updatedGroup.public);
      onSave(params);
    } else {
      const newGroup: Group = await createGroup(groupOptions);
      const params: onSaveParams = {
        updatedGroup: newGroup,
      };
      setUser({
        groups: [...(user?.groups ? user.groups : []), newGroup],
      });
      setName('');
      setIsPublic(true);
      onSave(params);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <IonItem>
        <IonLabel>{t('pages.settings.NAME')}</IonLabel>
        <IonInput
          autofocus
          value={name}
          placeholder={t('components.GroupForm.GroupName')}
          onIonChange={handleChange}
        />
      </IonItem>
      <IonItem>
        <IonLabel>{t('components.GroupForm.ROUTE')}</IonLabel>
        <IonSelect
          value={selectedRoute.id}
          placeholder={t('components.GroupForm.ROUTE_SELECT')}
          onIonChange={handleRouteChange}
          interface="alert"
        >
          {map(routes, (route) => (
            <IonSelectOption key={route.id} value={route.id}>
              {route.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>

      <IonItem lines="none">
        <IonLabel>{t('components.GroupForm.Private')}</IonLabel>
        <IonToggle
          slot="end"
          checked={!isPublic}
          onIonChange={(e) => {
            setIsPublic(!e.detail.checked);
          }}
        />
      </IonItem>
      <br />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="light" onClick={onDismiss} type="submit">
          {t('common.CANCEL')}
        </Button>
        &nbsp;
        <Button disabled={name.length < 3} onClick={handleSubmit} type="submit">
          {t('components.GroupForm.Save')}
        </Button>
      </div>
    </div>
  );
};
