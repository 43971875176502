import React, { useMemo } from 'react';
import { IonButton, IonContent, IonImg } from '@ionic/react';
import { TFunction, useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import './NewUserWizard.css';

interface IWizardProps {
  onClose?(e?: React.SyntheticEvent): void;
}

interface IWizardSlideProps {
  isLast?: boolean;
  onClose?(e?: React.SyntheticEvent): void;
}

const WizardSlide: React.FC<IWizardSlideProps> = ({
  children,
  isLast,
  onClose,
}) => (
  <SwiperSlide>
    <div className="slide-wrapper">
      <div className="slide-content">{children}</div>

      <div className="slide-footer">
        {isLast ? (
          <IonButton className="done-button" onClick={onClose}>
            Done
          </IonButton>
        ) : (
          <IonButton className="skip-button" onClick={onClose}>
            Skip
          </IonButton>
        )}
      </div>
    </div>
  </SwiperSlide>
);

const getSlideContent = ({ t }: { t: TFunction }) => [
  <div>
    <IonImg src="https://i2.wp.com/caminoforgood.com/wp-content/uploads/landscape.png?fit=2153%2C878&ssl=1" />
    <strong>{t('components.NewUserWizard.Welcome')}</strong>
    <br />
    <br />
    {t('components.NewUserWizard.Join')}
    <br />
    <br />
    {t('components.NewUserWizard.Asking')}
  </div>,
  <div>
    <IonImg src="https://i2.wp.com/caminoforgood.com/wp-content/uploads/landscape.png?fit=2153%2C878&ssl=1" />
    {t('components.NewUserWizard.View')}
  </div>,
  <ul style={{ textAlign: 'left' }}>
    {t('components.NewUserWizard.Check out')}
    <br />
    <br />
    <li>{t('components.NewUserWizard.Settings')}</li>
    <li>{t('components.NewUserWizard.Groups')}</li>
    <li>{t('components.NewUserWizard.Destinations')}</li>
    <li>{t('components.NewUserWizard.Map')}</li>
    <li>{t('components.NewUserWizard.First miles')}</li>
  </ul>,
  <div>
    {t('components.NewUserWizard.First five')}
    <br />
    <br />
    {t('components.NewUserWizard.Learn more')}
    <a href="https://www.caminoforgood.com">
      {t('components.NewUserWizard.Click')}
    </a>
  </div>,
];

export const NewUserWizard: React.FC<IWizardProps> = ({
  onClose,
}: IWizardProps) => {
  const { t } = useTranslation();
  const slideContent = useMemo(() => getSlideContent({ t }), [t]);

  return (
    <IonContent fullscreen scroll-y="false">
      <Swiper className="cfg-slides" pagination={true}>
        {slideContent.map((content, i) => (
          <WizardSlide isLast={i === slideContent.length - 1} onClose={onClose}>
            {content}
          </WizardSlide>
        ))}
      </Swiper>
    </IonContent>
  );
};
