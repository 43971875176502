import styled from '@emotion/styled';
import React from 'react';

import logo from '../../assets/logo_horizontal.svg';
import { LoginFooterLinks } from './LoginFooterLinks';

const LoginWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  display: flex;
  height: 40vw;
  margin: auto;
  margin-top: 10vh;
  max-height: 80vh;
  min-height: 580px;
  overflow: hidden;
  width: 60vw;
`;

const LoginImage = styled.div`
  background-image: url(/assets/camino_bg_4.jpg);
  background-size: auto 800px;
  background-position-x: 30%;
  background-position-y: 60%;
  background-repeat: no-repeat;
  width: 100%;
`;

const LoginInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 60px;
`;

const Logo = styled.img`
  height: 30px;
`;

type Props = {
  className?: string;
  title?: string;
  style?: React.CSSProperties;
};

export const LoginDesktopView: React.FC<Props> = ({
  children,
  className,
  style,
  title = 'Log In.',
}) => (
  <LoginWrapper className={className} style={style}>
    <LoginImage />
    <LoginInnerWrapper>
      <div>
        <Logo src={logo} />
      </div>
      <div>
        <h1 style={{ fontWeight: 800 }}>{title}</h1>
        {children}
      </div>
      <LoginFooterLinks />
    </LoginInnerWrapper>
  </LoginWrapper>
);
