import styled from '@emotion/styled';
import React from 'react';

type Props = {
  color?: string;
  highlight?: boolean;
  isRight?: boolean;
  subtitle?: string;
  title: React.ReactNode;
};

const DestinationCard = styled.div<{ highlight?: string; isRight?: boolean }>`
  align-items: center;
  background-color: var(--color-primary-200);
  border-radius: 10px;
  box-shadow: ${(p) =>
    p.highlight
      ? `0 0 0 2px var(--color-${p.highlight})`
      : '1px 1px 0 0px var(--color-secondary-200)'};
  display: flex;
  min-width: 0;
  position: relative;

  .avatars {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: repeat(auto-fit, 20px);
    margin-top: 4px;
  }

  .content {
    background-color: #f8f8f8;
    border-radius: ${(p) => (p.isRight ? '10px 0 0 10px' : '0 10px 10px 0')};
    flex: 1;
    font-size: 12px;
    min-width: 0;
    padding: 10px;
  }

  .number {
    align-items: center;
    color: var(--color-primary-700);
    display: flex;
    flex-direction: column;
    font-size: 10px;
    font-weight: 600;
    justify-content: center;
    padding: 10px;
    width: 50px;

    strong {
      color: var(--color-primary-500);
      font-size: 14px;
      font-weight: 400;
    }
  }

  &:after {
    background-color: var(--color-secondary-100);
    content: '';
    display: inline-block;
    height: 2px;
    position: absolute;
    ${(p) => (p.isRight ? 'right: -20px' : 'left: -20px')};
    width: 20px;
    z-index: -1;
  }
`;

const Line = styled.div<{ color?: string }>`
  --size: var(--timeline-size);
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: var(--size);

  &:after {
    --circle-size: calc(var(--size) - 2px);
    background-color: var(--color-${(p) => p.color});
    background-color: var(--color-primary-500);
    box-shadow: 0 0 0 4px white, 1px 1px 0px 4px var(--color-secondary-200);
    box-sizing: border-box;
    border-radius: 20px;
    content: '';
    display: inline-block;
    height: var(--circle-size);
    position: absolute;
    width: var(--circle-size);
  }
`;

const CardTitle = ({ subtitle, title }: any) => (
  <div style={{ lineHeight: '14px' }}>
    <div
      className="card-subtitle"
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {subtitle}
    </div>
    {title && (
      <>
        {' '}
        <br />
        <small style={{ color: '#999', fontWeight: 400 }}>{title}</small>
      </>
    )}
  </div>
);

export const TimelineCard: React.FC<Props> = ({
  children,
  color,
  highlight,
  isRight,
  subtitle,
  title,
}) => {
  const card = <div />;
  return (
    <>
      {isRight ? (
        <>
          <DestinationCard
            highlight={highlight ? color : undefined}
            isRight={isRight}
          >
            <div className="content">
              <CardTitle subtitle={subtitle} />
              <div className="avatars">{children}</div>
            </div>
            <div className="number">{title}</div>
          </DestinationCard>
          <Line color={color} />
          {card}
        </>
      ) : (
        <>
          {card}
          <Line color={color} />
          <DestinationCard
            highlight={highlight ? color : undefined}
            isRight={isRight}
          >
            <div className="number">{title}</div>
            <div className="content">
              <CardTitle subtitle={subtitle} />
              <div className="avatars">{children}</div>
            </div>
          </DestinationCard>
        </>
      )}
    </>
  );
};
