import styled from '@emotion/styled';
import { Button } from '../Button/Button';
import { arrowBack, arrowForward } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

const StyledStep = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 3px;
`;

const StepBody = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const StepActions = styled.div`
  display: inline-flex;
  justify-content: space-between;
  margin: 0 2rem 1rem;
`;
interface StepProps {
  goNextStep: () => void;
  goPreviousStep: () => void;
  // Tells you the active step right now
  currentStep: number;
  // And this is useful to know where you are
  isLast: boolean;
  isFirst: boolean;
  loggedIn: boolean;
  // Tells you the step in which you are right now, starting
  // from 1
  step?: number;
}
export const Step: React.FC<StepProps> = ({
  goNextStep,
  goPreviousStep,
  isFirst,
  isLast,
  loggedIn,
  children,
}) => {
  return (
    <StyledStep>
      <StepBody>{children}</StepBody>
      <StepActions>
        {isFirst ? (
          <div />
        ) : (
          <Button color="outline" disabled={isFirst} onClick={goPreviousStep}>
            <IonIcon icon={arrowBack} />
          </Button>
        )}
        {/* Same but with the last step */}
        {loggedIn && isLast ? null : (
          <Button
            className="step-button"
            disabled={isLast}
            onClick={goNextStep}
          >
            <IonIcon icon={arrowForward} />
          </Button>
        )}
      </StepActions>
    </StyledStep>
  );
};
