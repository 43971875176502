import { IonInput, IonItem, IonLabel, IonList } from '@ionic/react';

import React, { useState } from 'react';
import { Button } from '../Button/Button';
import { Text } from '../Text';
import { regexValidateEmail } from '../../util/isEmailValid';

export const ForgotPasswordForm = ({
  forgotPassword,
}: {
  forgotPassword: Function;
}) => {
  const [msg, setMsg] = useState('');
  const [email, setEmail] = useState('');
  const submit = async () => {
    try {
      await forgotPassword(email);
      setMsg('Please check your email for further instructions');
    } catch (e: any) {
      setMsg(e.message);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      {!msg && (
        <>
          <IonList>
            <IonItem>
              <IonLabel position="floating">Email</IonLabel>
              <IonInput
                autofocus={true}
                onIonChange={(e: CustomEvent) =>
                  setEmail((e.target as HTMLInputElement).value)
                }
                type="email"
                pattern={regexValidateEmail.toString()}
              />
            </IonItem>
          </IonList>
          <Button type="submit">Send</Button>
        </>
      )}
      {msg && (
        <>
          <Text
            style={{
              color: '#67ad31',
            }}
          >
            {msg}
          </Text>
        </>
      )}
    </form>
  );
};
