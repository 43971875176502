import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IonLoading } from '@ionic/react';
import { toNumber } from 'lodash';
import { PageWrapper } from '../components/PageWrapper';
import { useQueryParams } from '../hooks/useQueryParams';
import {
  PLANS,
  PlansListSlide,
} from '../components/PurchaseSlides/SelectPlanSlide';
import { Stepper } from '../components/Stepper/Stepper';
import { useHistory } from 'react-router';
import { UserContext } from '../context/UserContext';
import { SelectRouteSlide } from '../components/PurchaseSlides/SelectRouteSlide';
import { CheckoutSlide } from '../components/PurchaseSlides/CheckoutSlide';
import { Route, Subscription } from '../context/RouteContext';
import constants from '../util/constants';
import _ from 'lodash';

export const Purchase = () => {
  const {
    plan: planParam,
    slide: slideParam,
    route: routeParam,
  } = useQueryParams<{ plan?: PLANS; slide?: string; route: string }>();

  const [routes, setRoutes] = useState<Route[]>([]);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  useEffect(() => {
    (async () => {
      if (!routes.length) {
        const products = await fetch(
          `${constants.ENDPOINT}/stripe/products`
        ).then((res) => res.json());
        const subs = await fetch(
          `${constants.ENDPOINT}/stripe/subscriptions`
        ).then((res) => res.json());
        setSubscriptions(subs);
        setRoutes(products);
      }
    })();
  }, [routes]);

  const { loading, loggedIn } = useContext(UserContext);
  const [sliderLoading, setSliderLoading] = useState(true);

  const history = useHistory();

  // QUERY PARAMETER DATA HERE
  const [route, setRoute] = useState<string>(routeParam || 'camino/frances');
  const [selectedPlan, setSelectedPlan] = useState<PLANS>(
    planParam !== PLANS.FLAT ? PLANS.MONTHLY : PLANS.FLAT
  );
  const [slide, setSlide] = useState(planParam ? slideParam || 2 : 1);
  const onPlanSelect = (plan: PLANS) => {
    setSelectedPlan(plan);
    goNextStep();
  };

  useEffect(() => {
    // If we have a non logged in user, trying to go to a later slide
    // we will redirect them back.
    if (!loading && !loggedIn) {
      console.error('Redirecting user that is not logged in.');
    }
    setSliderLoading(false);
  }, [loading, loggedIn, planParam]);

  useEffect(() => {
    history.push({
      pathname: window.location.pathname,
      search: `?${new URLSearchParams({
        plan: selectedPlan,
        slide: slide.toString(),
        route: route || '',
      })}`,
    });
  }, [history, selectedPlan, slide, route]);

  const onRouteSelect = useCallback(
    (newRoute: string) => {
      setRoute(newRoute);
      history.push({
        pathname: window.location.pathname,
        search: `?${new URLSearchParams({
          plan: selectedPlan,
          slide: slide.toString(),
          route: newRoute || '',
        })}`,
      });
    },
    [history, selectedPlan, slide]
  );

  const onPurchase = async (data: any) => {
    (window.opener || window).postMessage(
      {
        type: 'on-purchase',
        data: JSON.stringify({
          plan: selectedPlan,
          route: route || '',
          hasAccount: data.hasAccount,
        }),
      },
      'https://caminoforgood.com/'
    );
  };

  const goNextStep = () => {
    const nextStep = toNumber(slide) + 1;
    setSlide(nextStep);
  };

  const goPreviousStep = () => {
    const previousStep = toNumber(slide) - 1;
    if (previousStep >= 1) {
      setSlide(previousStep);
    }
  };

  const steps = _.filter([
    {
      title: 'PLANS',
      // Render whatever you want here, we will improve this later
      element: () => (
        <PlansListSlide
          onPlanSelect={onPlanSelect}
          selectedPlan={selectedPlan}
        />
      ),
    },
    ...(selectedPlan !== PLANS.MONTHLY
      ? [
          {
            title: 'ROUTE',
            element: () => (
              // selectedPlan !== PLANS.MONTHLY ? (
              <SelectRouteSlide
                routes={routes}
                selectedRoutePath={route}
                onRouteSelect={onRouteSelect}
              />
            ),
          },
        ]
      : []),
    {
      title: 'CHECKOUT',
      element: () => (
        <CheckoutSlide
          plan={selectedPlan}
          route={route}
          routes={routes}
          subscriptions={subscriptions}
          onSuccess={onPurchase}
        />
      ),
    },
  ]);

  return (
    <>
      <PageWrapper
        gridStyle={{
          background: '#fafbfc',
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        }}
        style={
          { '--slide-footer-height': '60px', padding: 0 } as React.CSSProperties
        }
      >
        <IonLoading isOpen={loading || sliderLoading} />
        <Stepper
          id="purchaseStepper"
          loggedIn={!!loggedIn}
          goNextStep={goNextStep}
          goPreviousStep={goPreviousStep}
          currentStep={toNumber(slide)}
          steps={steps}
        />
      </PageWrapper>
    </>
  );
};
