import { IonIcon, isPlatform } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import React from 'react';

import { Button } from '../Button/Button';
import { Link } from '../Link/Link';

type Props = {
  continueText?: React.ReactNode;
  current?: number;
  disableNext?: boolean;
  image: React.ReactNode;
  loading?: boolean;
  onClose?(): void;
  onNext?(): void;
  onPrev?(): void;
  skip?: boolean;
  step: number;
  trackerName: string;
};

export const TrackerWizardSlide: React.FC<Props> = ({
  children,
  continueText = isPlatform('desktop') ? 'Continue' : 'Next',
  current,
  disableNext,
  loading,
  image,
  onClose,
  onNext,
  onPrev,
  step,
  trackerName,
}) => {
  return (
    <div
      style={{
        display: step === current ? 'block' : 'none',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'grid',
          fontSize: '18px',
          gridGap: '20px',
          gridTemplateRows: '0fr 1fr 0fr',
          height: '100%',
          lineHeight: '30px',
          padding: `30px ${isPlatform('desktop') ? 60 : 20}px`,
        }}
      >
        <div
          style={{
            display: 'inline-grid',
            justifyContent: 'center',
            gridTemplateColumns: '30px 0fr',
            whiteSpace: 'nowrap',
            gridGap: '10px',
            alignItems: 'center',
          }}
        >
          {image}
          <h3>{trackerName} Integration</h3>
        </div>
        <div
          style={{
            minHeight: 0,
          }}
        >
          {children}
        </div>

        {(onNext || onPrev) && (
          <div
            style={{
              paddingTop: '40px',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {onClose && <Link onClick={onClose}>Cancel</Link>}
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '0fr 0fr',
                gap: '10px',
              }}
            >
              {current && onPrev ? (
                <Button
                  color="light"
                  disabled={loading}
                  onClick={onPrev}
                  size={isPlatform('desktop') ? undefined : 'small'}
                >
                  Back
                </Button>
              ) : null}
              {onNext && (
                <Button
                  disabled={disableNext}
                  loading={loading}
                  onClick={onNext}
                  size={isPlatform('desktop') ? undefined : 'small'}
                >
                  {continueText} &nbsp;
                  <IonIcon icon={arrowForwardOutline} size="small" />
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
