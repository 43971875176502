import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { withApollo } from 'react-apollo';

import { Group } from './GroupContext';
import constants from '../util/constants';
import {
  CREATE_USER_LOG,
  DELETE_USER_LOG,
  UPDATE_USER_LOG,
} from '../queries/mileage';
import {
  AUTHORIZE_THIRD_PARTY,
  CURRENT_USER,
  FETCH_SAMPLE_TRACKER_LOGS,
  FRIENDS,
  LOGOUT,
  RESET_USER,
  SYNC_THIRD_PARTY,
  UPDATE_USER,
  UPDATE_CONTACT_INFO,
  UPDATE_USER_SETTINGS,
  USER,
  UPDATE_TRACKER_SETTINGS,
} from '../queries/user';
import { CREATE_USER_LOGS } from '../queries/mileage';
import { healthTracker } from '../util/healthTracker';
import {
  removeStorage,
  setStorage,
  getStorage,
} from '../hooks/useLocalStorage';
import { getAuth } from 'firebase/auth';
import { isPlatform } from '@ionic/react';
import { oneSignalUtil } from '../util/oneSignal';
import { Route, RouteSettings } from './RouteContext';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2';
import { CapacitorHealthkit } from '@perfood/capacitor-healthkit';
import { Capacitor } from '@capacitor/core';

export interface Log {
  id: string;
  mileage: number;
  user_id: string;
  created_at: string;
  updated_at: string;
  route_id: number;
  source: string;
  date: string;
  category: string;
}

export interface PublicUser {
  id: string;
  _key: number;
  completed: boolean;
  avatar: string;
  email: string;
  first_name: string;
  last_name: string;
  providers: string[];
  team?: any;
  user_settings: {
    distance_unit?: string;
    private?: boolean;
  };
  tracker_settings: {
    healthkit: number;
    fitbit: number;
  };
  route_settings: RouteSettings[];
}

export interface User extends PublicUser {
  groups: Group[];
  mobs: Group[];
  providers: string[];
  plan?: 'monthly' | 'one time' | 'team';
  cancellation_date?: number;
  fitbit_base_date: string;
  fitbit_categories: string[];
  fitbit_id: string;
  fitbit_mode: string;
  healthkit_base_date: string;
  healthkit_categories: string[];
  language: string;
  one_signal_id: string;
  ua_base_date: string;
  ua_categories: string[];
  ua_id: string;
  route_id?: number;
  route_slug?: string;
  route_settings: RouteSettings[];
  source: string;
  handle: string;
}

type SetUserConfig = { onSuccess?(): void; persist?: boolean };
type TrackerConfig = {
  date?: string | null;
  categories?: string[] | null;
  mode?: string | null;
  token?: string | null;
  deleteOnDisconnect?: boolean;
};

export interface UserConsumer {
  authorizeThirdParty(source: string, secret: string): Promise<User>;
  fetchCurrentUser(): Promise<User>;
  fetchSampleData(
    source: string
  ): Promise<{ categorized: Log[]; uncategorized: Log[] }>;
  getRouteSettings(route: Route, user?: PublicUser): RouteSettings;
  isTrackerActive(source: string): boolean;
  loggedIn?: boolean;
  loading?: boolean;
  logout?(): void;
  token?: string;
  friends: PublicUser[];
  user: User;
  createLog?({
    date,
    mileage,
  }: {
    date: string;
    mileage: number;
  }): Promise<User>;
  deleteLog?({ id }: { id: number }): Promise<User>;
  fetchUser(id: number | string): Promise<User>;
  resetUser?(): void;
  setFriends?(users: PublicUser[]): void;
  setToken(token: string): void;
  setUser(user: Partial<User>, setUserConfig?: SetUserConfig): void;
  syncLogs(): void;
  updateUser(
    config: Partial<User>
  ): Promise<{ error?: Error; success?: boolean }>;
  updateUserDisplayName(
    updatedUser: Partial<User>
  ): Promise<{ error?: { code: string }; success?: boolean }>;
  updateLog?({
    date,
    id,
    mileage,
  }: {
    date: string;
    id: number;
    mileage: number;
  }): Promise<User>;
  updateTrackerSettings(source: string, config: TrackerConfig): void;
  updateRouteAccess(route: Route, paymentStatus?: boolean): void;
}

export const UserContext = React.createContext<UserConsumer>(
  {} as UserConsumer
);
let _key = 0;

const initialUserState = {
  id: '',
  _key,
  completed: false,
  avatar: '',
  email: '',
  first_name: '',
  groups: [],
  language: '',
  last_name: '',
  created_at: '',
  mobs: [],
  providers: [],
  route_settings: [],
  user_settings: {
    distance_unit: constants.DISTANCE_UNITS.DEFAULT,
    private: false,
  },
  cancellation_date: 0,
  fitbit_base_date: '',
  fitbit_categories: [],
  fitbit_id: '',
  fitbit_mode: 'default',
  healthkit_base_date: '',
  healthkit_categories: [],
  one_signal_id: '',
  ua_base_date: '',
  ua_categories: [],
  ua_id: '',
  source: '',
  tracker_settings: {
    healthkit: 0,
    fitbit: 0,
  },
  handle: '',
};

class UserProviderClass extends Component<
  { client: any },
  Partial<UserConsumer>
> {
  _timer: any = 0;
  state = {
    loading: true,
    token: undefined,
    user: { ...initialUserState },
    friends: [],
  };

  componentDidMount = async () => {
    let user: User;
    const token = ((await getStorage('token')) as string) || '';
    this.setState({
      token,
    });
    if (token) {
      const currentUser = await this.fetchCurrentUser();
      this.fetchFriends();

      user = await currentUser;
      if (user) {
        oneSignalUtil.setUser(user);
      }

      if (isPlatform('cordova') && isPlatform('ios')) {
        InAppPurchase2.ready(async () => {
          const anyUnpaidRoute = _.some(user.route_settings, {
            payment_status: false,
          });

          anyUnpaidRoute && (await this.fetchCurrentUser());
        });
      }
      if (user) {
        this.syncLogs();
      }
    }
    this.setState({ loading: false });
  };

  componentDidUpdate(
    _prevProps: { client: any },
    { user }: Partial<UserConsumer>
  ) {
    if (!user?.id && this.state.user.id) {
      oneSignalUtil.getOneSignalId().then((one_signal_id: string) => {
        this.updateUser({ one_signal_id });
      });
    }
  }

  authorizeThirdParty = async (source: string, secret: string) => {
    const {
      data: {
        authorize: { user },
      },
    } = await this.props.client.mutate({
      mutation: AUTHORIZE_THIRD_PARTY,
      variables: {
        source,
        secret,
      },
    });

    this.setUser(user);

    return user;
  };

  fetchSampleData = async (source: string) => {
    try {
      if (source === 'healthkit') {
        return await healthTracker.fetchData(
          moment(new Date()).subtract(1, 'month').format('x')
        );
      } else {
        const {
          data: { fetchSampleTrackerLogs },
        } = await this.props.client.query({
          query: FETCH_SAMPLE_TRACKER_LOGS,
          variables: { source },
        });
        return fetchSampleTrackerLogs;
      }
    } catch (e) {
      console.log(`Error fetching sample from ${source}`, e);
    }
  };

  getRouteSettings = (
    route: Route,
    selectedUser: PublicUser = this.state.user
  ) =>
    _.find(selectedUser.route_settings, { route_id: route?.id }) ||
    ({
      destinations_visited: [],
      logs: [],
      mileage: 0,
      payment_status: false,
      route_id: route?.id,
      stamps: [],
    } as RouteSettings);

  isUAActive = (user: PublicUser) => {
    return _.includes(user.providers, 'under_armour');
  };

  resetUser = async () => {
    const {
      data: {
        resetUser: { user },
      },
    } = await this.props.client.mutate({
      mutation: RESET_USER,
    });

    this.setUser(user);
  };

  syncWithTracker = async (source: string) => {
    const {
      data: {
        sync: { user },
      },
    } = await this.props.client.mutate({
      mutation: SYNC_THIRD_PARTY,
      variables: {
        source,
      },
    });

    this.setUser(user);

    return user;
  };

  createLog = async ({ date, mileage }: { date: string; mileage: number }) => {
    const {
      data: {
        createUserLog: { user },
      },
    } = await this.props.client.mutate({
      mutation: CREATE_USER_LOG,
      variables: {
        date,
        mileage,
      },
    });

    this.setUser(user);

    return user;
  };

  deleteLog = async ({ id }: { id: number }) => {
    const {
      data: {
        deleteUserLog: { user },
      },
    } = await this.props.client.mutate({
      mutation: DELETE_USER_LOG,
      variables: {
        id,
      },
    });
    this.setUser(user);

    return user;
  };

  fetchFriends = async () => {
    try {
      const {
        data: { friends },
      } = await this.props.client.query({
        query: FRIENDS,
        fetchPolicy: 'cache-first',
      });

      this.setFriends(friends);
    } catch (e) {}
  };

  fetchCurrentUser = async () => {
    let user;
    try {
      const {
        data: { currentUser },
      } = await this.props.client.query({
        query: CURRENT_USER,
      });

      user = currentUser;
      this.setUser(user);
    } catch (e) {
      console.error('Failed on UserContext.setUser', e);
    }

    console.log('❤️ User fetched:', user?.id);
    this.setState({ loading: false });
    return user;
  };

  fetchUser = async (id: number) => {
    try {
      const {
        data: { user },
      } = await this.props.client.query({
        query: USER,
        variables: { id },
      });
      return user;
    } catch (e) {
      console.error('Error fetching user:', e);
    }
  };

  logout = async () => {
    await this.props.client.mutate({
      mutation: LOGOUT,
    });
    removeStorage('token');
    if (isPlatform('cordova')) {
      await FirebaseAuthentication.signOut();
    }
    await getAuth().signOut();
    this.setState({ user: { ...initialUserState }, token: undefined });
  };

  syncLogs = async () => {
    await this.updateHealthkitLogs();
    await this.fetchCurrentUser();
  };

  setFriends = (friends: PublicUser[]) => {
    const uniqFriends: PublicUser[] = _.uniqBy(
      friends as PublicUser[],
      'email'
    ).filter(
      (f: PublicUser) =>
        !f.user_settings?.private && f.email !== this.state.user?.email
    );
    this.setState({ friends: uniqFriends });
  };

  setUser = async (
    userConfig: Partial<User>,
    { persist, onSuccess }: SetUserConfig = {}
  ) => {
    _key++;
    this.setState(
      { user: { ...this.state.user, ...userConfig, _key } },
      async () => {
        if (persist) {
          await this.props.client.mutate({
            mutation: UPDATE_USER_SETTINGS,
            variables: {
              distance_unit: this.state.user.user_settings.distance_unit,
              private: this.state.user.user_settings.private,
            },
          });
          onSuccess && onSuccess();
        } else {
          onSuccess && onSuccess();
        }
      }
    );
  };

  updateUser = async (userConfig: Partial<User>) => {
    let errorsSeen;
    try {
      const {
        data: { updateUser },
        errors,
      } = await this.props.client.mutate(
        {
          mutation: UPDATE_USER,
          variables: {
            userConfig,
          },
        },
        { errorPolicy: 'all' }
      );
      if (errors) {
        errorsSeen = errors;
        throw new Error(_.get(errors, '[0].message', 'Unknown error occurred'));
      }
      const { user } = updateUser;
      this.setUser(user);
      if (userConfig.route_id) {
        this.fetchFriends();
      }
      return { success: true };
    } catch (error) {
      console.error('Error updating user:', error, errorsSeen);
      const errorInstance =
        error instanceof Error ? error : new Error('An unknown error occurred');

      return { error: errorInstance };
    }
  };

  setToken = (token: string) => {
    this.setState({ loading: true, token }, async () => {
      console.log('setStorage', token);
      setStorage('token', token);
      await this.fetchFriends();
      await this.fetchCurrentUser();
    });
  };

  updateLog = async ({
    date,
    id,
    mileage,
  }: {
    date: string;
    id: number;
    mileage: number;
  }) => {
    const {
      data: {
        updateUserLog: { user },
      },
    } = await this.props.client.mutate({
      mutation: UPDATE_USER_LOG,
      variables: {
        date,
        id,
        mileage,
      },
    });
    this.setUser(user);

    return user;
  };

  updateUserDisplayName = async (updatedUser: Partial<User>) => {
    let error;
    try {
      const {
        data: { updateUserDisplayName },
        errors,
      } = await this.props.client.mutate({
        mutation: UPDATE_CONTACT_INFO,
        variables: {
          updatedUser,
        },
      });
      if (!_.isEmpty(errors)) {
        error = {
          code: _.get(
            errors,
            '[0].extensions.exception.errors[0].original.extensions.code',
            'UNKNOWN_ERROR'
          ),
        };
      }

      const { user } = updateUserDisplayName;

      this.setUser(user);
      return { success: true };
    } catch (e) {
      console.error(e);
      if (!error && e instanceof Error && e.message) {
        error = { code: e.message };
      }
      return { error, success: false };
    }
  };

  updateTrackerSettings = async (
    source: string,
    { date, categories, mode, token, deleteOnDisconnect }: TrackerConfig
  ) => {
    const {
      data: {
        updateTrackerSettings: { user },
      },
    } = await this.props.client.mutate({
      mutation: UPDATE_TRACKER_SETTINGS,
      variables: {
        source,
        categories,
        mode,
        date,
        token,
        deleteOnDisconnect,
      },
    });
    await this.setUser(user);

    if (source === 'healthkit' && this.isTrackerActive('healthkit')) {
      await this.updateHealthkitLogs(true);
    }
  };

  updateHealthkitLogs = async (allLogs = false) => {
    let isHealthKitAvailable = false;

    if (!Capacitor.isNativePlatform()) return;

    await CapacitorHealthkit.isAvailable().then(() => {
      isHealthKitAvailable = true;
    });
    if (
      this.state.user &&
      this.isTrackerActive('healthkit') &&
      isPlatform('cordova') &&
      isHealthKitAvailable
    ) {
      const {
        user: { healthkit_base_date, healthkit_categories },
      } = this.state;

      const route_id = this.state.user.tracker_settings?.healthkit || 1;
      const routeSetting = _.find(this.state.user.route_settings, {
        route_id,
      }) as any as RouteSettings;

      const latestHealthKitLog = _.last(
        _.sortBy(
          _.filter(routeSetting.logs || [], { source: 'healthkit' }),
          'date'
        )
      );

      let syncDate =
        latestHealthKitLog &&
        moment(latestHealthKitLog.date, 'YYYY-MM-DD').subtract('1', 'week') >=
          moment(healthkit_base_date, 'x')
          ? moment(latestHealthKitLog.date, 'YYYY-MM-DD')
              .subtract('1', 'week')
              .format('x')
          : healthkit_base_date;

      syncDate = allLogs ? healthkit_base_date : syncDate;

      const { categorized, uncategorized } = await healthTracker.fetchData(
        syncDate
      );

      const logs = healthkit_categories?.length
        ? categorized.filter((l: Partial<Log>) =>
            // @ts-ignore :(
            healthkit_categories.includes(l.category)
          )
        : uncategorized;

      const {
        data: {
          createUserLogs: { user },
        },
      } = await this.props.client.mutate({
        mutation: CREATE_USER_LOGS,
        variables: {
          logs,
          source: 'healthkit',
          date: allLogs ? undefined : syncDate,
        },
      });

      await this.setUser(user);
    }
  };

  isTrackerActive = (source: string) =>
    _.includes(this.state.user.providers, source);

  updateRouteAccess = (
    selectedRoute: Route,
    payment_status: boolean = true
  ) => {
    const route_settings: RouteSettings[] = [...this.state.user.route_settings];
    const route = _.find(route_settings, { route_id: selectedRoute.id });

    if (route) {
      route.payment_status = payment_status;
      this.setUser({ route_settings });
    }
  };

  render() {
    const { friends, loading, token, user } = this.state;

    return (
      <UserContext.Provider
        value={{
          friends,
          loading,
          loggedIn: !!(user && user.first_name),
          token,
          user,
          authorizeThirdParty: this.authorizeThirdParty,
          fetchCurrentUser: this.fetchCurrentUser,
          fetchSampleData: this.fetchSampleData,
          getRouteSettings: this.getRouteSettings,
          createLog: this.createLog,
          deleteLog: this.deleteLog,
          fetchUser: this.fetchUser,
          isTrackerActive: this.isTrackerActive,
          logout: this.logout,
          resetUser: this.resetUser,
          setFriends: this.setFriends,
          setToken: this.setToken,
          setUser: this.setUser,
          syncLogs: this.syncLogs,
          updateUser: this.updateUser,
          updateLog: this.updateLog,
          updateTrackerSettings: this.updateTrackerSettings,
          updateUserDisplayName: this.updateUserDisplayName,
          updateRouteAccess: this.updateRouteAccess,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export const UserProvider = withApollo(UserProviderClass);
