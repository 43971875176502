import React, { HTMLAttributes, useState } from 'react';

type Tab = {
  content?: JSX.Element;
  onClick?(e?: React.SyntheticEvent): void;
  props?: any;
};

type Props = {
  components?: {
    TabButton?: React.ComponentType;
    TabContainer?: React.ComponentType;
    TabContent?: React.ComponentType;
  };
  tabs: Tab[];
};

const DefaultContainer: React.FC<HTMLAttributes<any>> = ({
  children,
  ...props
}) => <div {...props}>{children}</div>;

const DefaultTab: React.FC<{
  disabled?: boolean;
  onClick?(e?: React.SyntheticEvent): void;
}> = ({ children, ...props }) => <button {...props}>{children}</button>;

export const Tabs: React.FC<Props> = ({ components = {}, tabs }) => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    TabButton = DefaultTab,
    TabContainer = DefaultContainer,
    TabContent = DefaultContainer,
  } = components;

  return (
    <>
      <TabContainer
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {tabs.map(({ onClick = () => {}, props }, idx) => (
          <TabButton
            key={idx}
            {...props}
            disabled={idx === activeTab}
            onClick={(e: React.SyntheticEvent) => {
              setActiveTab(idx);
              onClick(e);
            }}
          />
        ))}
      </TabContainer>
      <TabContent>
        {tabs.map(({ content }, idx) => (
          <div
            key={idx}
            style={{ display: idx === activeTab ? 'block' : 'none' }}
          >
            {content}
          </div>
        ))}
      </TabContent>
    </>
  );
};
