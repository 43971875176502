import {
  IonDatetime,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { heart } from 'ionicons/icons';
import { uniqBy } from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

// TODO Update image for other trackers
import image from '../../assets/fitbit.svg';
import { Log, UserContext } from '../../context/UserContext';
import { useBoolean } from '../../hooks/useBoolean';
import constants, { TrackerType } from '../../util/constants';
import { MileageForm } from '../MileageForm';
import { Caption, P } from '../Text';
import { TrackerChooseCategories } from './TrackerChooseCategories';
import { TrackerWizardSlide } from './TrackerWizardSlide';
import { useNotification } from '../../context/NotificationContext';
import { useActiveRoute } from '../../hooks/useActiveRoute';

type Props = {
  isOpen: boolean;
  onAuthorize(): Promise<boolean>;
  onDidDismiss(): void;
  source: TrackerType;
};

const useStepper = () => {
  const [step, setStep] = useState(0);

  const jumpTo = (s: number) => setStep(s);
  const nextStep = () => setStep((s) => s + 1);
  const prevStep = () => setStep((s) => s - 1);

  return [step, nextStep, prevStep, jumpTo] as const;
};

const today = moment().format('YYYY-MM-DD');

export const TrackerWizard: React.FC<Props> = ({
  isOpen,
  onAuthorize,
  onDidDismiss,
  source,
}) => {
  const { notify } = useNotification();
  const { fetchSampleData, updateTrackerSettings } = useContext(UserContext);
  const { logs: currentLogs } = useActiveRoute();
  const tracker = constants.TRACKERS[source];
  const [activities, setActivities] = useState<Log[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [categorized, setCategorized] = useState(false);
  const [date, setDate] = useState<string>(today);
  const [loading, startLoad, endLoad] = useBoolean(false);
  const [logs, setLogs] = useState<Log[]>([]);
  const [step, nextStep, prevStep, jumpTo] = useStepper();
  const { t } = useTranslation();

  const closeWizard = () => {
    onDidDismiss();
    setActivities([]);
    setCategories([]);
    setCategorized(false);
    setDate(today);
    jumpTo(0);
  };

  const loadSampleData = async () => {
    startLoad();
    source !== 'healthkit' && (await onAuthorize());
    const samples = await fetchSampleData(tracker.id);
    if (samples) {
      const allCategories = uniqBy(
        samples.categorized,
        (l: Log) => l.category
      ).map((l: Log) => l.category);
      setActivities(samples.categorized.reverse());
      setLogs(samples.uncategorized.reverse());
      setCategories(allCategories);
      nextStep();
    } else {
      notify(t('components.TrackerWizard.OnError'));
    }
    endLoad();
  };

  const updateTracker = async () => {
    startLoad();
    await updateTrackerSettings(tracker.id, {
      categories: selectedCategories,
      date,
      mode: categorized ? 'categorized' : 'default',
    });
    nextStep();
    endLoad();
  };

  const defaultOption = () => {
    setSelectedCategories([]);
    jumpTo(3);
  };

  const displayDefaultOrAdvancedText = () =>
    categorized ? (
      <Trans
        i18nKey="components.TrackerWizard.AdvancedUsers"
        components={{
          highlight: <strong />,
        }}
      />
    ) : (
      <>{t('components.TrackerWizard.ImportOptions')}</>
    );

  const displaySampleLogs = () =>
    categorized ? (
      <>
        {activities.map((log) => (
          <MileageForm key={log.id} log={log} />
        ))}
      </>
    ) : (
      <>
        {logs.map((log) => (
          <MileageForm key={log.id} log={log} />
        ))}
      </>
    );

  return (
    <IonModal isOpen={!!isOpen} onDidDismiss={onDidDismiss}>
      {[
        {
          children: (
            <div>
              {t('components.TrackerWizard.ImportLogs')} {tracker.name}.
            </div>
          ),
          continueText: t('components.TrackerWizard.ContinueText'),
          onNext: loadSampleData,
        },
        {
          children: (
            <>
              <div>
                <IonSegment
                  mode="ios"
                  onIonChange={({ detail: { value } }) =>
                    setCategorized(value === 'categorized')
                  }
                  value={categorized ? 'categorized' : 'default'}
                >
                  <IonSegmentButton value="default">
                    <IonLabel>{t('components.TrackerWizard.Default')}</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="categorized">
                    <IonLabel>
                      {t('components.TrackerWizard.Categorized')}
                    </IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </div>
              <div style={{ height: '100%', overflowY: 'scroll' }}>
                <Caption style={{ margin: '10px 0' }}>
                  {displayDefaultOrAdvancedText()}
                </Caption>
                <IonList>{displaySampleLogs()}</IonList>
              </div>
            </>
          ),
          onNext: () => (categorized ? nextStep() : defaultOption()),
        },
        {
          children: categorized ? (
            <TrackerChooseCategories
              categories={categories}
              selectedCategories={selectedCategories}
              onChange={setSelectedCategories}
              source={source}
            />
          ) : (
            <Caption>{t('components.TrackerWizard.DefaultText')}</Caption>
          ),
          disableNext: !selectedCategories.length,
        },
        {
          children: (
            <>
              <Caption>{t('components.TrackerWizard.SelectDate')}</Caption>
              <IonItem>
                <IonLabel>{t('components.TrackerWizard.ImportFrom')}</IonLabel>
                <IonDatetime
                  placeholder={`${moment(date).format('DD MMM YYYY')}`}
                  style={{
                    color: 'var(--color-primary-600)',
                    gridColumnStart: 2,
                  }}
                  // displayFormat="DD MMM YYYY"
                  min={moment().subtract(1, 'years').format('YYYY')}
                  max={moment().format('YYYY')}
                  onIonChange={({ detail }: CustomEvent) => {
                    setDate(detail.value!);
                  }}
                  value={date}
                />
              </IonItem>
            </>
          ),
          onNext: updateTracker,
          onPrev: categorized ? prevStep : () => jumpTo(1),
        },
        {
          children: (
            <div style={{ height: '100%', overflowY: 'scroll' }}>
              <Caption style={{ margin: '10px 0' }}>
                {t('components.TrackerWizard.ReviewLogs')}
              </Caption>
              {currentLogs
                .filter((l: Log) => l.source === source)
                .map((log: Log) => (
                  <MileageForm key={log.id} log={log} />
                ))}
            </div>
          ),
        },
        {
          children: (
            <>
              <P>
                {t('components.TrackerWizard.NowConnected')} {tracker.name}!
              </P>
              <br />
              <Caption>
                <Trans
                  i18nKey="components.TrackerWizard.NoteText1"
                  components={{
                    highlight: <strong />,
                  }}
                />{' '}
                {tracker.name}
                {t('components.TrackerWizard.NoteText2')} {tracker.name}
                {t('components.TrackerWizard.NoteText3')}
              </Caption>
            </>
          ),
          continueText: t('components.TrackerWizard.CloseWizard'),
          onClose: undefined,
          onNext: closeWizard,
          onPrev: undefined,
        },
      ].map((props, s) => (
        <TrackerWizardSlide
          key={`Tracker-${s}`}
          current={step}
          image={
            source === 'fitbit' ? (
              <IonImg src={image} style={{ height: '30px' }} />
            ) : (
              <IonIcon icon={heart} size="large" />
            )
          }
          loading={loading}
          onClose={closeWizard}
          onNext={nextStep}
          onPrev={prevStep}
          step={s}
          trackerName={tracker.name}
          {...props}
        />
      ))}
    </IonModal>
  );
};
