import { IonBadge, IonItem, IonIcon, IonMenuToggle } from '@ionic/react';
import { add } from 'ionicons/icons';
import React from 'react';

const isDisabled = (path: string) => path === window.location.pathname;

export const SidebarItem = ({
  color = 'light',
  badgeValue = undefined,
  icon,
  link,
  name,
  href,
  target,
  onClick = () => {},
}: any) => (
  <IonMenuToggle auto-hide="false">
    <IonItem
      style={{ cursor: 'pointer' }}
      disabled={isDisabled(link)}
      color={color}
      detail={false}
      onClick={onClick}
      routerLink={link}
      href={href}
      target={target}
    >
      <IonIcon
        style={{ color: 'var(--color-primary-500)' }}
        icon={icon || add}
        size="small"
        slot="start"
      />
      {name}
      {badgeValue && (
        <IonBadge
          style={{
            '--background': 'var(--ion-color-danger)',
            '--color': 'white',
          }}
          slot="end"
        >
          {badgeValue}
        </IonBadge>
      )}
    </IonItem>
  </IonMenuToggle>
);
