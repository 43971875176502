import _ from 'lodash';
import React, {
  createContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Route } from './RouteContext';
import { useActiveRoute } from '../hooks/useActiveRoute';

export interface Waypoint {
  from_start: number;
  lat: number;
  lng: number;
}

interface IPathContext {
  getNextWaypoint(m: number): Waypoint;
  getLastWaypoint(m: number): Waypoint;
  getUnlockedWaypoints(m: number): Waypoint[];
  getWaypoints(route: Route): Promise<Waypoint[]>;
  loading: boolean;
  loadPaths(path: string): Promise<Waypoint[]>;
}

export const PathContext = createContext<IPathContext>({
  loading: true,
  getNextWaypoint: () => ({ from_start: 0, lat: 0, lng: 0 }),
  getLastWaypoint: () => ({ from_start: 0, lat: 0, lng: 0 }),
  getUnlockedWaypoints: () => [],
  getWaypoints: async () => [],
  loadPaths: async () => [],
});

const waypointCache: { [key: string]: Waypoint[] } = {};

export const PathProvider = ({ children }: any) => {
  const { route } = useActiveRoute();
  const [loading, setLoading] = useState(true);
  const [activePath, setActivePath] = useState<string>('');
  const [waypointList, setWaypointList] = useState<{
    [key: string]: Waypoint[];
  }>({});

  const loadPaths = useCallback(
    async (path: string) => {
      if (waypointCache[path]) {
        setWaypointList((prev) => ({
          ...prev,
          [path]: waypointCache[path],
        }));
        setLoading(false);
        return waypointCache[path];
      }
      if (!path) return [];

      const points = await fetch(route.meta.waypoints).then((res) =>
        res.json()
      );

      waypointCache[path] = points;
      setWaypointList((prev) => ({
        ...prev,
        [path]: points,
      }));
      setLoading(false);
      setActivePath(path);
      return points;
    },
    [route.meta.waypoints]
  );

  const getNextWaypoint = useCallback(
    (m: number, path: string = activePath) =>
      _.filter(waypointList[path], (p) => p.from_start > m)[0],
    [waypointList, activePath]
  );

  const getLastWaypoint = useCallback(
    (m: number, path: string = activePath) =>
      _.filter(
        _.reverse([...waypointList[path]]),
        (p) => p.from_start < m
      )[0] || waypointList[path][0],
    [waypointList, activePath]
  );

  const getUnlockedWaypoints = useCallback(
    (m: number, path: string = activePath) =>
      _.filter(waypointList[path], (p) => p.from_start < m),
    [waypointList, activePath]
  );

  const getWaypoints = useCallback(
    async (route: Route) => {
      if (!waypointList[route.path]) {
        return await loadPaths(route.path);
      }
      return waypointList[route.path];
    },
    [waypointList, loadPaths]
  );

  useEffect(() => {
    if (route && route.path && !waypointList[route.path]) {
      loadPaths(route.path);
    }
  }, [route, waypointList, loadPaths]);

  const contextValue = useMemo(
    () => ({
      getNextWaypoint,
      getLastWaypoint,
      getUnlockedWaypoints,
      getWaypoints,
      loading,
      loadPaths,
    }),
    [
      getNextWaypoint,
      getLastWaypoint,
      getUnlockedWaypoints,
      getWaypoints,
      loading,
      loadPaths,
    ]
  );

  return (
    <PathContext.Provider value={contextValue}>{children}</PathContext.Provider>
  );
};
