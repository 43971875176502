import { Preferences } from '@capacitor/preferences';
import { IonIcon, IonImg, isPlatform, useIonRouter } from '@ionic/react';
import { initializeApp } from 'firebase/app';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  browserPopupRedirectResolver,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  getRedirectResult,
  linkWithRedirect,
  onAuthStateChanged,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';
import { mailOutline as emailIcon } from 'ionicons/icons';
import _ from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import logoApple from '../assets/apple.png';
import logoFacebook from '../assets/facebook.png';
import logoGoogle from '../assets/google.png';
import { useLocalStorage } from '../hooks/useLocalStorage';
import constants from '../util/constants';
import { UserContext } from './UserContext';

// Initialize Firebase
const app = initializeApp({
  apiKey: 'AIzaSyDF77wy8VM5U8_LB_48i5E_Q-ySxiPObVE',
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: 'https://camino-for-good-co.firebaseio.com',
  projectId: 'camino-for-good-co',
  storageBucket: 'camino-for-good-co.appspot.com',
  messagingSenderId: '594573542603',
  appId: '1:594573542603:web:02b7e0c96a92a70ec7074e',
  measurementId: 'G-S4BR1YTEEN',
});

const AppleProvider = new OAuthProvider('apple.com');
const GoogleProvider = new GoogleAuthProvider();
const FacebookProvider = new FacebookAuthProvider();

AppleProvider.addScope('email');
AppleProvider.addScope('name');

const auth = getAuth(app);
setPersistence(auth, browserSessionPersistence).catch((error) => {
  console.error('Error setting persistence:', error);
});

let userLoggedIn = false;

export type ProviderListItem = {
  appProvider?: string;
  id: string;
  logo: any;
  provider?: any;
};

const filteredProviderList = () => {
  const providerList = [
    {
      id: 'google',
      appProvider: 'google.com',
      provider: GoogleProvider,
      logo: <IonImg src={logoGoogle} />,
    },
    {
      id: 'facebook',
      appProvider: 'facebook.com',
      provider: FacebookProvider,
      logo: <IonImg src={logoFacebook} />,
    },
    {
      id: 'iOS',
      appProvider: 'apple.com',
      provider: AppleProvider,
      logo: <IonImg src={logoApple} />,
    },
    {
      id: 'camino',
      logo: <IonIcon icon={emailIcon} />,
    },
  ];

  return isPlatform('android')
    ? _.filter(providerList, (provider) => provider.id !== 'iOS')
    : providerList;
};

export const ProviderList = filteredProviderList();

interface LoginBody {
  email: string;
  password: string;
}

interface LoginConsumer {
  error: string;
  forgotPassword(email: string): Promise<void>;
  loading: boolean;
  providerLogin(provider: ProviderListItem): Promise<void>;
  providerList: ProviderListItem[];
  login(body: LoginBody & { email: string }): Promise<void>;
  signup(body: LoginBody): Promise<void>;
  validate: Function;
}

const verify = async (body: any = {}) => {
  const idToken = await auth.currentUser?.getIdToken();
  try {
    const { token } = await fetch(`${constants.ENDPOINT}/auth/verify`, {
      body: JSON.stringify({
        ...body,
      }),
      headers: {
        AuthToken: idToken || '',
        'Content-Type': 'application/json',
      },
      method: 'post',
    }).then((res) => res.json());
    console.log('### token verify', token);
    userLoggedIn = true;
    return token;
  } catch (e) {
    console.error('### Verify error:', e);
  }
  return null;
};

const linkAccounts = async (onError: Function) => {
  const { value: auth_method } = await Preferences.get({
    key: 'auth_method',
  });
  const { value: shouldPromptToLink } = await Preferences.get({
    key: 'auth_link',
  });

  if (
    !!shouldPromptToLink &&
    window.confirm(
      `We have detected two accounts with the same email address. Would you like to merge your ${_.capitalize(
        shouldPromptToLink
      )} and ${_.capitalize(auth_method || '')} accounts?`
    )
  ) {
    await Preferences.set({
      key: 'auth_link',
      value: '',
    });

    try {
      const provider = ProviderList.find((p) => p.id === shouldPromptToLink);

      if (provider && provider.provider) {
        await linkWithRedirect(
          auth.currentUser!,
          provider.provider,
          browserPopupRedirectResolver
        );
        return true;
      } else {
        return false;
      }
    } catch (e) {
      onError(e);
    }
    return false;
  }

  await Preferences.set({
    key: 'auth_link',
    value: '',
  });
};

export const LoginContext = createContext<LoginConsumer>({
  loading: true,
} as LoginConsumer);

export const LoginProvider = ({ children }: any) => {
  const router = useIonRouter();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // @ts-ignore - unused variable
  const [get, _set, remove] = useLocalStorage(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const { setToken } = useContext(UserContext);

  const validate = useCallback(
    async (body?: Partial<LoginBody>) => {
      let source = undefined,
        handle = undefined;
      if (
        window.location.pathname.includes('/register/affiliate') ||
        window.location.pathname.includes('/redeem')
      ) {
        const url = window.location.pathname;
        if (url.includes('/register/affiliate')) {
          [source, handle] = url.replace('/register/affiliate/', '').split('/');
        } else {
          [handle] = url.replace('/redeem/', '').split('/');
        }
      }

      const token = await verify({ source, handle, ...body });
      if (token) {
        setToken(token);
      }
    },
    [setToken]
  );

  const handleAuthStateChanged = useCallback(
    async (user) => {
      if (userLoggedIn) return;
      console.log('### Auth state changed:', user);
      if (user) {
        await linkAccounts(setError);
        await validate(user);
      }
      setLoading(false);
    },
    [validate]
  );

  useEffect(() => {
    if (userLoggedIn) return;
    console.log('### useEffect', handleAuthStateChanged);
    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);

    // Call getRedirectResult to handle the result of the redirect operation
    getRedirectResult(auth)
      .then(async (result) => {
        if (result && result.user) {
          console.log('Redirect result on mount:', result);
          await handleAuthStateChanged(result.user);

          const redirectUrl =
            ((await get('redirectUrl')) as string) || '/dashboard/home';
          if (redirectUrl && window.location.pathname !== '/upgrade') {
            remove('redirectUrl');
            router.push(redirectUrl);
          }
        }
      })
      .catch((error) => {
        console.error('Error handling redirect result on mount:', error);
      });

    return () => unsubscribe();
  }, [handleAuthStateChanged, get, remove, router]);

  const login = async (
    body: LoginBody & { email: string; password: string }
  ) => {
    try {
      const { email, password } = body;
      await signInWithEmailAndPassword(auth, email, password);
      await Preferences.set({
        key: 'auth_method',
        value: 'camino',
      });
      await linkAccounts(setError);
      await validate(body);
    } catch (error) {
      console.error('Login error:', error);
      setError((error as any).message);
    }
  };

  const signup = async (body: LoginBody) => {
    try {
      const { email, password } = body;
      await createUserWithEmailAndPassword(auth, email, password);
      await signInWithEmailAndPassword(auth, email, password);
      await Preferences.set({
        key: 'auth_method',
        value: 'camino',
      });
      await validate(body);
    } catch (error) {
      console.error('Signup error:', error);
      setError((error as any).message);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await Preferences.set({
        key: 'auth_method',
        value: 'camino',
      });
      await sendPasswordResetEmail(auth, email, {
        url: `${process.env.REACT_APP_FRONTEND}/login?email=${email}`,
        handleCodeInApp: false,
      });
    } catch (error) {
      console.error('Forgot password error:', error);
      setError((error as any).message);
    }
  };

  const providerLogin = async (provider: ProviderListItem) => {
    try {
      await Preferences.set({
        key: 'auth_method',
        value: provider.id,
      });

      if (window.location.hostname === 'localhost') {
        await signInWithPopup(auth, provider.provider);
      } else {
        await signInWithRedirect(auth, provider.provider);
      }
    } catch (error) {
      console.error('### Provider login error:', error);
      setError((error as any).message);
    }
  };

  return (
    <LoginContext.Provider
      value={{
        error,
        forgotPassword,
        loading,
        login,
        providerList: ProviderList,
        providerLogin,
        signup,
        validate,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
