import { IonFab, IonThumbnail, isPlatform } from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookIcon, FacebookShareButton } from 'react-share';
import { ImageModal } from '../ImageModal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperInstance } from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';

export type ImageSlide = {
  src: string;
  thumbnail?: string;
  title: string;
};

type Props = {
  images: ImageSlide[];
  disabled?: boolean;
};

export const ImageSlider: React.FC<Props> = ({ images, disabled }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState();

  const handleSlideChange = (swiper: SwiperInstance) => {
    setSlideIndex(swiper.realIndex);
  };

  return (
    <div
      style={{
        pointerEvents: disabled ? 'none' : 'initial',
        position: 'relative',
      }}
    >
      <ImageModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalImage={modalImage}
      />
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 10,
          background: 'rgba(0,0,0,0.75)',
          color: 'white',
          fontSize: '12px',
          fontWeight: 600,
          padding: '3px 6px',
          borderRadius: '4px',
        }}
      >
        {slideIndex + 1}/{images.length}
      </div>
      <div
        onClick={(e: React.SyntheticEvent) => {
          // @ts-ignore
          setModalImage(e.target.style.backgroundImage);
          !isPlatform('desktop') && setShowModal(true);
        }}
        style={
          {
            '--swiper-navigation-color': 'white',
          } as React.CSSProperties
        }
      >
        <Swiper
          modules={[Navigation]}
          loop
          navigation
          onSlideChange={handleSlideChange}
          style={{
            backgroundColor: '#f4f4f4',
            filter: disabled ? 'blur(4px)' : '',
          }}
        >
          {images.map((img: ImageSlide) => (
            <SwiperSlide
              key={img.src}
              style={{
                ...(isPlatform('mobile')
                  ? { height: '75vw' }
                  : { height: '400px' }),
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  backgroundImage: `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.75)), url(${img.src})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  filter: 'blur(10px)',
                  height: '110%',
                  position: 'absolute',
                  width: '110%',
                  zIndex: -1,
                }}
              />
              <IonFab vertical="bottom" horizontal="end">
                <FacebookShareButton
                  url={img.src as string}
                  hashtag="#caminoforgood"
                  quote={`${t('components.DestinationDetails.ShareText1')} ${
                    img.title
                  }${t('components.DestinationDetails.ShareText2')}`}
                  // disabled={isLocked}
                  onClick={(e) => e.stopPropagation()}
                >
                  <FacebookIcon size={25} round={true} />
                </FacebookShareButton>
                {/* TODO react-share does not currently support sharing images ???
                https://stackoverflow.com/questions/45144386/how-to-implement-share-to-twitter-with-react-js
                 <TwitterShareButton
                  hashtags={['caminoforgood']}
                  onClick={(e) => e.stopPropagation()}
                  // disabled={isLocked}
                  title={`${t('components.DestinationDetails.ShareText1')} ${
                    img.title
                  }${t('components.DestinationDetails.ShareText2')}`}
                  url={img.src as string} //@TODO display image instead of url
                >
                  <TwitterIcon size={25} round={true} />
                </TwitterShareButton> */}
              </IonFab>
              <IonThumbnail
                style={{
                  '--size': isPlatform('desktop') ? '100%' : '100vw',
                  backgroundImage: `url(${img.src})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  ...(isPlatform('mobile') ? { height: '75vw' } : {}),
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
