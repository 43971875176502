import {
  IonContent,
  IonMenu,
  IonList,
  IonFooter,
  IonToolbar,
  isPlatform,
  IonItem,
} from '@ionic/react';
import _ from 'lodash';
import {
  barChartOutline as barChart,
  bookOutline as book,
  cog,
  homeOutline as home,
  logOutOutline,
  mapOutline,
  peopleOutline as people,
  flagOutline,
  helpCircleOutline,
  shuffleOutline,
} from 'ionicons/icons';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import { UserDetail } from '../UserDetail/UserDetail';
import { SidebarItem } from './SidebarItem';
import { useHistory } from 'react-router';
import { useActiveRoute } from '../../hooks/useActiveRoute';

export const Sidebar = ({ onToggle, open }: any) => {
  const { logout = _.noop, user } = useContext(UserContext);
  const { payment_status, route } = useActiveRoute();
  const history = useHistory();
  const { t } = useTranslation();

  const clearSession = async () => {
    await logout();
    history.push('/dashboard');
  };

  const sidebarItems = [
    {
      icon: home,
      link: '/dashboard/home',
      name: t('components.Sidebar.Dashboard'),
    },
    {
      icon: barChart,
      link: '/dashboard/progress',
      name: t('components.Sidebar.Your Progress'),
    },
    {
      icon: book,
      link: '/dashboard/passport',
      name: t('components.Sidebar.Passport'),
    },
    {
      icon: mapOutline,
      link: '/dashboard/map',
      name: t('components.Sidebar.Map'),
    },
    {
      icon: flagOutline,
      link: '/dashboard/destinations',
      name: t('components.Sidebar.Destinations'),
    },
    {
      icon: people,
      link: '/dashboard/groups',
      name: t('components.Sidebar.Groups'),
    },
  ];

  const color = isPlatform('desktop') ? '' : '';

  return (
    <IonMenu
      color={color}
      contentId="main"
      disabled={!open}
      side={isPlatform('desktop') ? 'start' : 'end'}
      style={{ width: isPlatform('desktop') ? '240px' : undefined }}
      swipeGesture={false}
      onIonDidClose={onToggle}
      onIonDidOpen={onToggle}
    >
      <IonToolbar
        color="dark"
        style={{
          '--min-height': isPlatform('desktop') ? '60px' : '0px',
          ...(isPlatform('ios') ? { paddingTop: '30px' } : {}),
        }}
      >
        <UserDetail color={color} size="small" user={user} route={route} />
      </IonToolbar>
      <br />
      <IonContent color={color}>
        <IonList color={color}>
          <IonItem>
            <strong>{route.name}</strong>
          </IonItem>
          {sidebarItems.map((item) => (
            <SidebarItem color={color} key={item.link} {...item} />
          ))}
        </IonList>
      </IonContent>
      <IonFooter style={{ marginBottom: '10px' }}>
        <SidebarItem
          color={color}
          icon={shuffleOutline}
          name={t('components.Sidebar.Routes')}
          link="/dashboard/routes"
        />
        <SidebarItem
          color={color}
          icon={helpCircleOutline}
          name={t('components.Sidebar.Support')}
          href="https://caminoforgood.com/support/"
          target="blank"
        />
        <SidebarItem
          badgeValue={payment_status ? undefined : 1}
          color={color}
          icon={cog}
          link="/dashboard/settings"
          name={t('components.Sidebar.Settings')}
        />
        <SidebarItem
          color={color}
          icon={logOutOutline}
          name={t('components.Sidebar.Logout')}
          onClick={clearSession}
        />
      </IonFooter>
    </IonMenu>
  );
};
