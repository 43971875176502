import { RefresherEventDetail } from '@ionic/core/components';
import {
  IonContent,
  IonGrid,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import React, { CSSProperties, useCallback } from 'react';

import { Spinner } from './Spinner/Spinner';
import { useNotification } from '../context/NotificationContext';

export type PageRefreshCallback = (
  onComplete: (msg?: string) => void,
  e?: CustomEvent<RefresherEventDetail>
) => void;

type Props = {
  gridStyle?: any;
  loading?: boolean;
  onRefresh?: PageRefreshCallback;
  padding?: number | string;
  style?: CSSProperties;
};

/**
 * @description
 * The PageWrapper component aims to provide every page in the application
 * with consistent spacing and also limits the maximum size when viewed
 * from a desktop browser.
 */
export const PageWrapper: React.FC<Props> = ({
  children,
  gridStyle,
  loading,
  onRefresh,
  padding = '10px',
  style,
}) => {
  const { notify } = useNotification();
  const refresh = useCallback(
    (e) => {
      onRefresh &&
        onRefresh((msg?: string) => {
          msg && notify(msg);
          e.detail.complete();
        }, e);
    },
    [notify, onRefresh]
  );

  return (
    <IonContent
      style={{
        '--padding-bottom': 0,
        '--padding-end': 0,
        '--padding-start': 0,
        '--padding-top': 0,
        ...style,
      }}
    >
      {loading ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          {onRefresh && (
            <IonRefresher slot="fixed" pullFactor={0.75} onIonRefresh={refresh}>
              <IonRefresherContent
                pullingText="Pull to refresh"
                refreshingSpinner="crescent"
              />
            </IonRefresher>
          )}
          <IonGrid
            className="grid-wrapper"
            fixed
            style={{ padding: padding, ...gridStyle }}
          >
            {children}
          </IonGrid>
        </>
      )}
    </IonContent>
  );
};
