import styled from '@emotion/styled';
import { IonAvatar, isPlatform, IonIcon } from '@ionic/react';
import { map, size, take } from 'lodash';
import React, { useContext, useMemo } from 'react';

import { DestinationContext } from '../../context/DestinationContext';
import { PublicUser, UserContext } from '../../context/UserContext';
import constants from '../../util/constants';
import { TimelineCard } from './TimelineCard';
import { renderDistance } from '../../util/renderDistance';
import { groupUsersByTown } from '../../util/groupUsersByTown';
import { Route } from '../../context/RouteContext';
import { ellipsisHorizontalSharp } from 'ionicons/icons';

type TimelineProps = {
  currentUser: PublicUser;
  distanceUnit?: string;
  route: Route;
  sortedUsers: PublicUser[];
};

const TimelineWrapper = styled.div<{ cards: number; isDesktop: boolean }>`
  --timeline-size: 18px;
  align-items: center;
  display: grid;
  grid-gap: 0 20px;
  grid-template-columns: 1fr var(--timeline-size) 1fr;
  grid-template-rows: repeat(${(p) => p.cards}, 40px);
  min-height: 60%;
  min-width: 400px;
  margin: 20px ${(p) => (p.isDesktop ? 'auto' : '5px')} 0;
  padding: 20px 0 0;
  position: relative;
  ${(p) => (p.isDesktop ? 'width: 50%;' : '')}

  &:before {
    border-left: var(--timeline-size) solid var(--color-secondary-100);
    content: '';
    display: inline-block;
    height: 100%;
    left: calc(50% - var(--timeline-size) * 0.5);
    position: absolute;
    width: 0;
  }

  &:after {
    border: calc(var(--timeline-size) * 0.5) solid transparent;
    border-bottom-color: var(--color-secondary-100);
    content: '';
    display: inline-block;
    height: 0;
    left: calc(50% - var(--timeline-size) * 0.5);
    position: absolute;
    top: calc(var(--timeline-size) * -1);
    width: 0;
    z-index: -1;
  }
`;

export const Timeline: React.FC<TimelineProps> = ({
  currentUser,
  distanceUnit = constants.DISTANCE_UNITS.DEFAULT,
  route,
  sortedUsers,
}) => {
  const { destinations } = useContext(DestinationContext);
  const { getRouteSettings } = useContext(UserContext);
  const usersGroupedByTown = useMemo(
    () =>
      groupUsersByTown({
        destinations,
        getRouteSettings: (user) => {
          return getRouteSettings(route, user);
        },
        users: sortedUsers,
      }),
    [destinations, getRouteSettings, route, sortedUsers]
  );

  let idx = -1;

  return (
    <TimelineWrapper
      cards={size(usersGroupedByTown)}
      isDesktop={isPlatform('desktop')}
    >
      {map(usersGroupedByTown, (u, townName) => {
        let highlight = false;
        // Not sure if this is the best way to pull out the milestone value
        // but every user in this group will have the same milestone value
        const distanceFromStart = u[0].town.from_start;
        idx++;
        const combinedUsers = map(u, (user: PublicUser) => {
          highlight = highlight ? true : user.id === currentUser.id;

          return (
            <IonAvatar
              key={user.id}
              style={{
                '--border-radius': '5px',
                height: '20px',
                width: '20px',
              }}
            >
              <img alt={user.first_name} src={user.avatar} />
            </IonAvatar>
          );
        });

        let displayedUsers = take(combinedUsers, 7);

        if (combinedUsers.length > 7) {
          displayedUsers.push(
            <IonIcon
              color="primary"
              icon={ellipsisHorizontalSharp}
              style={{
                fontSize: '16px',
                display: 'grid',
                gridGap: '2px',
                marginTop: '4px',
              }}
            />
          );
        }

        return (
          <TimelineCard
            color={'red-400'}
            highlight={highlight}
            key={townName}
            isRight={!!(idx % 2)}
            subtitle={townName}
            title={
              <>
                {renderDistance(distanceFromStart, distanceUnit, {
                  displayUnit: false,
                })}
                <strong>{distanceUnit}</strong>
              </>
            }
          >
            {isPlatform('desktop') ? displayedUsers : take(combinedUsers, 3)}
          </TimelineCard>
        );
      })}
    </TimelineWrapper>
  );
};
