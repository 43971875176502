import {
  informationCircleOutline,
  radioOutline,
  shareOutline,
} from 'ionicons/icons';
import React, { CSSProperties } from 'react';

import { IDestination } from '../../context/DestinationContext';
import { IonIcon } from '@ionic/react';
import { Tabs } from '../Tabs/Tabs';
import { Button } from '../Button/Button';
import { DestinationAudio } from './DestinationAudio';
import { DestinationDescription } from './DestinationDescription';
import { DestinationShare } from './DestinationShare';

export const DestinationDetails = ({
  destination,
  locked,
}: {
  destination: IDestination;
  locked?: boolean;
}) => {
  return (
    <div
      style={{
        filter: locked ? 'blur(4px)' : '',
        pointerEvents: locked ? 'none' : 'initial',
      }}
    >
      <Tabs
        tabs={[
          {
            content: <DestinationDescription destination={destination} />,
            props: {
              children: 'Info',
              icon: informationCircleOutline,
            },
          },
          ...(destination.audio
            ? [
                {
                  content: (
                    <DestinationAudio
                      destination={destination}
                      disabled={locked}
                    />
                  ),
                  props: {
                    children: 'Audio',
                    icon: radioOutline,
                  },
                },
              ]
            : []),
          {
            content: (
              <DestinationShare destination={destination} disabled={locked} />
            ),
            props: {
              children: 'Share',
              icon: shareOutline,
            },
          },
        ]}
        components={{
          TabButton: ({ children, disabled, icon, onClick }: any) => (
            <Button
              color={disabled ? 'light' : 'medium'}
              // disabled={disabled}
              onClick={onClick}
              size="small"
              style={
                {
                  '--ion-color-light': 'white',
                  boxShadow: '0 0 10px #ccc',
                  borderRadius: '14px',
                  flex: 1,
                } as CSSProperties
              }
            >
              <IonIcon color={disabled ? 'medium' : 'light'} icon={icon} />
              &nbsp; {children}
            </Button>
          ),
        }}
      />
    </div>
  );
};
