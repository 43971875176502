import styled from '@emotion/styled';
import { IonBadge, IonIcon, IonProgressBar, isPlatform } from '@ionic/react';
import {
  checkmarkCircleOutline,
  chevronDownOutline,
  chevronUpOutline,
  locationOutline,
} from 'ionicons/icons';
import React, { CSSProperties, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from '../../context/RouteContext';
import { UserContext } from '../../context/UserContext';
import { formatCurrency } from '../../util/formatCurrency';
import { renderDistance } from '../../util/renderDistance';
import { Card } from '../Card/Card';
import { RouteCardFooter } from './RouteCardFooter';

type Props = {
  activeRoute?: Route;
  isActive?: boolean;
  /**
   * If true, force the desktop view. If false, force mobile view
   */
  isDesktop?: boolean;
  isDisabled?: boolean;
  isLoggedIn?: boolean;
  /**
   * If true, will not display the price
   */
  isPaid?: boolean;
  onActivate?(route: Route): void;
  onPurchase?(route: Route): void;
  onSelect?(route?: Route): void;
  showBadges?: boolean;
  showButtons?: boolean; // showButtons mode
  route: Route;
  selected?: boolean;
};

const StyledCard = styled(Card)<{
  active?: boolean;
  disabled?: boolean;
  expanded?: boolean;
}>`
  ${(p) => (p.active ? 'box-shadow: 0 0 0 4px var(--color-border);' : '')}
  cursor: pointer;
  max-height: ${(p) =>
    p.expanded ? '450px' : '165px'}; /* TODO make more dynamic */
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  overflow: hidden;
  transition: 0.4s ease max-height;

  &.isDesktop > div {
    background-color: #${(p) => (p.active ? 'eee' : 'f4f4f4')};
    background-size: 200px 100%;
    background-repeat: no-repeat;
    background-position-x: 0;
    padding-left: 200px;
  }
`;

const Subtitle = ({
  distance,
  isActive,
  isPaid,
  product,
  showBadges,
  showPricing,
  purchasedText,
}: any) => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <div>
      {showBadges && (
        <>
          <IonBadge
            color={showPricing ? 'success' : 'dark'}
            style={{ textTransform: 'initial' }}
          >
            {showPricing
              ? formatCurrency(
                  product.amount / 100,
                  product.currency?.toUpperCase()
                )
              : distance}
          </IonBadge>

          {isPaid ? (
            <IonBadge style={{ marginLeft: '8px' }} color="tertiary">
              {purchasedText}
            </IonBadge>
          ) : (
            ''
          )}
        </>
      )}
    </div>
    <IonIcon
      icon={checkmarkCircleOutline}
      size="large"
      style={{ visibility: isActive ? 'visible' : 'hidden' }}
    />
  </div>
);

export const RouteCard: React.FC<Props> = ({
  activeRoute,
  isActive: forceActive,
  isDesktop: forceDesktop,
  isDisabled,
  isLoggedIn = true,
  isPaid: forcePaid,
  onActivate = () => {},
  onPurchase = () => {},
  onSelect,
  route,
  selected = false,
  showButtons = true,
  showBadges = true,
}) => {
  const { getRouteSettings, user } = useContext(UserContext);
  const { t } = useTranslation();
  const isActive =
    forceActive === undefined ? activeRoute?.id === route.id : forceActive;
  const userRouteSettings = isLoggedIn
    ? getRouteSettings(route)
    : {
        destinations_visited: [],
        logs: [],
        mileage: 0,
        payment_status: false,
        route_id: route.id,
        stamps: [],
      };
  const isDesktop =
    forceDesktop === undefined ? isPlatform('desktop') : forceDesktop;
  const isPaid =
    forcePaid === undefined
      ? userRouteSettings?.payment_status || user.plan === 'monthly'
      : forcePaid;
  const showPricing = !isPaid || !showButtons;

  return (
    <StyledCard
      active={!isDisabled && isActive}
      bg={`${route.route_image}`}
      disabled={isDisabled}
      expanded={selected}
      footer={
        <RouteCardFooter
          active={isActive}
          isPaid={isPaid}
          onPurchase={onPurchase}
          onSelect={onActivate}
          showButtons={showButtons}
          route={route}
          settings={userRouteSettings}
        />
      }
      className={isDesktop ? 'isDesktop' : ''}
      onClick={() => !isDisabled && onSelect && onSelect(route)}
      // @ts-ignore
      style={
        {
          '--color-border': isDesktop ? '#376492' : 'var(--color-primary-500)',
        } as CSSProperties
      }
      subtitle={
        <Subtitle
          isSelectable={onSelect === undefined}
          isActive={isActive}
          distance={renderDistance(
            userRouteSettings?.mileage,
            user.user_settings?.distance_unit
          )}
          isPaid={isPaid}
          product={route.product}
          showBadges={showBadges}
          showPricing={showPricing}
          purchasedText={t('pages.routes.PURCHASED')}
        />
      }
      textColor={isDesktop ? 'dark' : 'light'}
      title={route.name}
      titleProps={{ className: 'ion-text-left' }}
    >
      {userRouteSettings?.mileage && route.meta.distance ? (
        <IonProgressBar
          value={(userRouteSettings?.mileage || 0.1) / route.meta.distance}
        />
      ) : null}

      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <IonIcon icon={locationOutline} /> {t('countries.Spain')}
        </div>
        {!isDisabled && onSelect && (
          <IonIcon
            icon={selected ? chevronUpOutline : chevronDownOutline}
            onClick={() => onSelect && onSelect(route)}
          />
        )}
      </div>
    </StyledCard>
  );
};
