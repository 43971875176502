import React from 'react';
import { IonCheckbox, IonImg, IonItem, IonLabel } from '@ionic/react';
import { isEmpty, without } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Caption } from '../Text';
import { TrackerType } from '../../util/constants';
import compassImage from '../../assets/compass_map.png';

type Props = {
  categories: string[];
  selectedCategories: string[];
  onChange: React.Dispatch<React.SetStateAction<string[]>>;
  source: TrackerType;
};

export const TrackerChooseCategories: React.FC<Props> = ({
  categories,
  selectedCategories,
  onChange,
  source,
}) => {
  const { t } = useTranslation();

  return !isEmpty(categories) ? (
    <>
      <Caption>{t('components.TrackerWizard.CategorizedText')}</Caption>
      <div>
        {categories.map((name) => (
          <IonItem key={name}>
            <IonCheckbox
              checked={selectedCategories.includes(name)}
              slot="start"
              onIonChange={({ detail }) =>
                onChange((chosenCategory) =>
                  detail.checked
                    ? [...chosenCategory, name]
                    : without(selectedCategories, name)
                )
              }
            />
            <IonLabel>{name}</IonLabel>
          </IonItem>
        ))}
      </div>
    </>
  ) : (
    <div
      style={{
        display: 'grid',
        gridGap: '20px',
        gridTemplateRows: '120px 50px 1fr',
        justifyContent: 'center',
      }}
    >
      <IonImg src={compassImage} style={{ height: '120px', padding: '20px' }} />
      <strong
        style={{
          color: 'var(--color-secondary-500)',
          display: 'inline-block',
          textAlign: 'center',
        }}
      >
        {t('components.TrackerWizard.NoCategoriesText')}
      </strong>
      <div>
        <Trans i18nKey="components.TrackerWizard.NoCategoriesPt1" />
        <span style={{ textTransform: 'capitalize' }}>{source}</span>
        <Trans
          i18nKey="components.TrackerWizard.NoCategoriesPt2"
          components={{
            highlight: <strong />,
          }}
        />
      </div>
    </div>
  );
};
