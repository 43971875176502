import { IonInput, IonItemDivider, IonLabel, IonItem } from '@ionic/react';
import { isEmpty, map } from 'lodash';

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User, UserContext } from '../context/UserContext';
import { isEmailValid } from '../util/isEmailValid';
import { shouldEditEmail } from '../util/shouldEditEmail';

import { Button } from './Button/Button';

type UserName = {
  first?: string;
  last?: string;
};

export const ContactInfoForm = () => {
  const { user, updateUserDisplayName } = useContext(UserContext);
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<UserName>({
    first: user?.first_name,
    last: user?.last_name,
  });
  const [isEditing, setEditing] = useState(false);
  const [hasErrorMsg, setErrorMsg] = useState({
    messages: { name: '', email: '', response: '' },
  });
  const { t } = useTranslation();

  const isFormValid = () => {
    // form starts off valid
    let isValid = true;
    // if the user's email should be edited
    if (shouldEditEmail(user.email)) {
      // isValid will represent if the user's new email should be edited or not
      isValid = isEmailValid(email);
    }
    // if first name is less than 3 characters long form is not valid
    if (name.first?.length! < 3) {
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit: React.MouseEventHandler = async (e) => {
    e.preventDefault();
    let updatedUser: Partial<User> = {
      id: user.id,
      first_name: name.first,
      last_name: name.last,
      ...(shouldEditEmail(user.email) ? { email: email.toLowerCase() } : {}),
    };
    let response = await updateUserDisplayName(updatedUser);
    if (response.success) {
      setErrorMsg({
        messages: { name: '', email: '', response: '' },
      });
      setEditing(false);
    }
    if (response.error) {
      const errorMessage = response.error.code
        ? t(`ERRORS.${response.error.code}`)
        : t('ERRORS.DEFAULT');
      setErrorMsg({
        messages: {
          ...hasErrorMsg.messages,
          response: errorMessage,
        },
      });
      return;
    }
  };

  const onClose = () => {
    setEmail('');
    setName({ first: user.first_name, last: user.last_name });
    setErrorMsg({
      messages: { name: '', email: '', response: '' },
    });
    setEditing(false);
  };

  const contactForm = () => {
    return (
      <div style={{ padding: '20px' }}>
        <div
          style={{
            backgroundColor: 'var(--color-primary-100)',
            borderRadius: 5,
            marginBottom: 10,
          }}
        >
          {(!isFormValid() || !isEmpty(hasErrorMsg.messages.response)) && (
            <IonItem>
              <p style={{ color: 'var(--ion-color-danger)' }}>
                {map(hasErrorMsg.messages, (msg) => {
                  return (
                    !isEmpty(msg) && (
                      <>
                        - {msg}
                        <br />
                      </>
                    )
                  );
                })}
              </p>
            </IonItem>
          )}

          <IonItem>
            <IonLabel>{t('pages.settings.FIRST_NAME')}</IonLabel>
            &nbsp;
            <IonInput
              color={isEmpty(hasErrorMsg.messages.name) ? 'dark' : 'danger'}
              placeholder={t('pages.settings.FIRST_NAME')}
              onIonChange={(e) => {
                // @ts-ignore FIXME
                setName({ first: e.detail.value, last: name.last });

                if (e.detail.value?.length! <= 2) {
                  setErrorMsg({
                    messages: {
                      ...hasErrorMsg.messages,
                      name: 'First Name must be at least 3 characters long.',
                    },
                  });
                } else {
                  setErrorMsg({
                    messages: { ...hasErrorMsg.messages, name: '' },
                  });
                }
              }}
              style={{
                backgroundColor: 'var(--color-primary-100)',
                padding: 5,
                borderRadius: 5,
              }}
              value={name.first}
            />
          </IonItem>
          <IonItem>
            <IonLabel>{t('pages.settings.LAST_NAME')}</IonLabel>
            &nbsp;
            <IonInput
              placeholder={t('pages.settings.LAST_NAME_OPTIONAL')}
              onIonChange={(e) =>
                // @ts-ignore FIXME
                setName({ first: name.first, last: e.detail.value })
              }
              style={{
                backgroundColor: 'var(--color-primary-100)',
                padding: 5,
                borderRadius: 5,
              }}
              value={name.last}
            />
          </IonItem>
          {shouldEditEmail(user.email) && (
            <IonItem>
              <IonLabel>{t('pages.settings.EMAIL')}</IonLabel>
              &nbsp;
              <IonInput
                // TODO: Look into using type or another method of email validation besides regexp
                type="email"
                color={isEmpty(hasErrorMsg.messages.email) ? 'dark' : 'danger'}
                autofocus={true}
                placeholder={t('pages.settings.EMAIL')}
                onIonChange={(e) => {
                  setEmail(e.detail.value!);
                  if (!isEmailValid(e.detail.value!)) {
                    setErrorMsg({
                      messages: {
                        ...hasErrorMsg.messages,
                        email: t(
                          'pages.settings.EMAIL_NOT_FORMATTED_CORRECTLY'
                        ),
                      },
                    });
                  } else {
                    setErrorMsg({
                      messages: { ...hasErrorMsg.messages, email: '' },
                    });
                  }
                }}
                style={{
                  backgroundColor: 'var(--color-primary-100)',
                  padding: 5,
                  borderRadius: 5,
                }}
                value={email}
              />
            </IonItem>
          )}
          {!shouldEditEmail(user.email) && (
            <>
              <IonItem lines="none">
                <IonLabel>{t('pages.settings.EMAIL')}</IonLabel>
                {user.email}
              </IonItem>
              <IonItem>
                <IonLabel>
                  <p>{t('pages.settings.Contact us to change your email')}</p>
                </IonLabel>
              </IonItem>
            </>
          )}
        </div>
        <Button color="light" onClick={onClose} type="submit">
          {t('common.CANCEL')}
        </Button>
        &nbsp;
        <Button disabled={!isFormValid()} onClick={handleSubmit} type="submit">
          {t('pages.settings.SAVE')}
        </Button>
      </div>
    );
  };

  const contactInfo = () => {
    return (
      <>
        <IonItemDivider style={{ paddingTop: '25px' }}>
          {t('pages.settings.CONTACT_INFO')}
          <Button
            style={{ marginBottom: 5, marginRight: 15 }}
            slot="end"
            disabled={isEditing}
            onClick={() => setEditing(true)}
            type="submit"
          >
            {t('pages.settings.EDIT')}
          </Button>
        </IonItemDivider>

        <IonItem>
          <IonLabel>{t('pages.settings.NAME')}</IonLabel>
          {user?.first_name + ' ' + user?.last_name}
        </IonItem>
        <IonItem>
          <IonLabel>{t('pages.settings.EMAIL')}</IonLabel>
          {!shouldEditEmail(user.email) && user.email}
        </IonItem>
      </>
    );
  };

  return isEditing ? contactForm() : contactInfo();
};
