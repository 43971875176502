import { IonContent, IonPage, isPlatform } from '@ionic/react';
import React, { ReactElement, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Caption, P } from '../components/Text';

import { NewUserWizard } from '../components/NewUserWizard/NewUserWizard';
import { LoginDesktopView } from '../components/LoginView/LoginDesktopView';
import { LoginMobileView } from '../components/LoginView/LoginMobileView';
import { LoginForm } from '../components/LoginForm/LoginForm';
import { LoginContext } from '../context/LoginContext';
import { Link } from '../components/Link/Link';
import { useLocalStorage } from '../hooks/useLocalStorage';

export const Signup = ({ message }: { message: ReactElement }) => {
  const { loading, signup } = useContext(LoginContext);
  const [get] = useLocalStorage();
  const { t } = useTranslation();
  const [wizard, showWizard] = useState<boolean>(true);
  const [hasLoggedIn, setLoggedIn] = useState<string | number | boolean | null>(
    true
  );

  useEffect(() => {
    const load = async () => {
      const storageItem = await get('hasLoggedIn');
      setLoggedIn(storageItem);
    };
    load();
  }, [get, hasLoggedIn]);

  const showNewWizard = wizard && isPlatform('cordova') && !hasLoggedIn;

  const LoginContent = isPlatform('desktop')
    ? LoginDesktopView
    : LoginMobileView;

  return (
    <IonPage>
      {showNewWizard && <NewUserWizard onClose={() => showWizard(false)} />}
      <IonContent
        style={{
          '--background': isPlatform('desktop') ? '#eee' : '',
          alignItems: 'center',
          display: 'flex',
          height: '100vh',
        }}
      >
        <div style={{ display: loading ? 'none' : 'initial' }}>
          <LoginContent title="Sign up.">
            {message || <P color="grey">{t('pages.login.intro')}</P>}
            <br />
            <LoginForm submitText="Sign up" onSubmit={signup} />
            <br />
            <Caption>
              Already have an account? <Link href="/login">Login</Link>
              <br />
              <Link href="/forgot-password">Forgot Password</Link>
            </Caption>
          </LoginContent>
        </div>
      </IonContent>
    </IonPage>
  );
};
