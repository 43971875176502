import React from 'react';

import { IonButton } from '@ionic/react';
import { Spinner } from '../Spinner/Spinner';

/* TODO It would be nice to get the proper types for IonButton */
type Props = React.ButtonHTMLAttributes<{}> & {
  color?: string;
  disabled?: boolean;
  expand?: 'block' | 'full';
  loading?: boolean;
  fill?: 'outline' | 'solid' | 'clear' | 'default' | undefined;
  mode?: 'ios' | 'md';
  onClick?: React.MouseEventHandler;
  size?: string;
  style?: React.CSSProperties;
};

const getOutlineStyle = (color: string) =>
  color === 'outline'
    ? {
        '--border-color': '#eee',
        '--border-style': 'solid',
        '--border-width': '1px',
      }
    : {};

export const Button: React.FC<Props> = ({
  children,
  color = 'dark',
  disabled,
  loading,
  mode = 'ios',
  fill,
  size,
  style,
  ...props
}) => (
  <IonButton
    disabled={loading || disabled}
    color={color}
    mode={mode}
    fill={fill}
    role="button"
    style={{
      '--padding-end': size === 'small' ? '15px' : '30px',
      '--padding-start': size === 'small' ? '15px' : '30px',
      ...getOutlineStyle(color),
      ...style,
    }}
    {...props}
  >
    {loading ? <Spinner color="light" /> : children}
  </IonButton>
);
