import { OneSignal as OneSignalPlugin } from '@ionic-native/onesignal';
import { isPlatform } from '@ionic/react';
import { User } from '../context/UserContext';
window.OneSignal = window.OneSignal || [];

class OneSignalUtil {
  hasPermission: boolean = false;
  hasPrompted: boolean = false;
  instance: any;
  isSubscribed: boolean = false;
  oneSignalId: string = '';

  constructor() {
    if (isPlatform('cordova')) {
      this.instance = Promise.resolve(OneSignalPlugin);
    } else {
      this.instance = new Promise((resolve, reject) => {
        window.OneSignal.push(() => {
          window.OneSignal.getUserId((id: string) => {
            this.oneSignalId = id;
            resolve(window.OneSignal);
          });
        });
      });
    }
    this.init();
  }

  getOneSignalId = async () => {
    await this.instance;
    return this.oneSignalId;
  };

  init = async () => {
    const instance = await this.instance;
    if (isPlatform('cordova')) {
      instance.startInit(
        process.env.REACT_APP_ONE_SIGNAL_APP_ID!,
        process.env.REACT_APP_GOOGLE_PROJECT_NUMBER
      );

      // Set your iOS Settings
      const iosSettings = {
        kOSSettingsKeyAutoPrompt: false,
        kOSSettingsKeyInAppLaunchURL: false,
      };
      instance.setLogLevel({ logLevel: 6, visualLevel: 0 });

      instance.inFocusDisplaying(
        OneSignalPlugin.OSInFocusDisplayOption.InAppAlert
      );

      instance.handleNotificationReceived().subscribe(() => {
        console.log('do something when notification is received');
      });

      instance.handleNotificationOpened().subscribe((openResult: any) => {
        console.log(
          'do something when a notification is opened',
          JSON.stringify(openResult)
        );
      });
      instance.iOSSettings(iosSettings);

      instance.endInit();
      instance.getPermissionSubscriptionState((status: any) => {
        console.log('status', status);
        this.hasPrompted = status.permissionStatus.hasPrompted;

        // status.subscriptionStatus.userSubscriptionSetting; // Bool
        this.oneSignalId = status.subscriptionStatus.userId;
        this.hasPermission = isPlatform('ios')
          ? status.permissionStatus.status === 2 // iOS only: Integer: 0 = Not Determined, 1 = Denied, 2 = Authorized
          : status.permissionStatus.state === 1; //Android only: Integer: 1 = Authorized, 2 = Denied
        this.isSubscribed = status.subscriptionStatus.subscribed;

        if (!this.hasPermission || !this.hasPrompted) {
          instance.addTrigger('prompt_ios', 'true');
        }
      });
    } else {
      //A callback function that will be called when the browser's current notification permission has been obtained, with one of 'default', 'granted', or 'denied'.
      instance.getNotificationPermission((permission: string) => {
        if (permission === 'default') {
          // Only shows up if the user isn't already subscribed
          instance.showSlidedownPrompt({ force: true });
          this.hasPrompted = true;
        }
        this.hasPermission = permission === 'granted';
      });
      instance.isPushNotificationsEnabled((isEnabled: boolean) => {
        this.isSubscribed = isEnabled;
      });
    }
  };

  isAvailable = () => {
    return typeof window.OneSignal === 'function';
  };

  status = async () => {
    const instance = await this.instance;
    // We need to wait for these callbacks to be triggered before knowing the real status.
    if (isPlatform('cordova')) {
      await new Promise((resolve) => {
        instance.getPermissionSubscriptionState(resolve);
      });
    } else {
      const notificationPermissions = new Promise((resolve) => {
        instance.getNotificationPermission(resolve);
      });
      const pushNotificationsEnabled = new Promise((resolve) => {
        instance.isPushNotificationsEnabled(resolve);
      });
      await Promise.all([notificationPermissions, pushNotificationsEnabled]);
    }

    return {
      hasPermission: this.hasPermission,
      hasPrompted: this.hasPrompted,
      isSubscribed: this.isSubscribed,
      oneSignalId: this.oneSignalId,
    };
  };

  sendSelfNotification = async (message: string) => {
    const instance = await this.instance;
    if (isPlatform('cordova')) {
      instance.postNotification({
        contents: { en: message },
        include_player_ids: [this.oneSignalId],
      });
    } else {
      instance.sendSelfNotification('New Notification', message);
    }
  };

  sendTag = async (key: string, value: string) => {
    const instance = await this.instance;
    instance.sendTag(key, value);
  };

  setExternalUserId = async (id: string) => {
    const instance = await this.instance;
    return instance.setExternalUserId(id);
  };

  setSubscription = async (status: boolean) => {
    const instance = await this.instance;
    instance.setSubscription(status);
  };

  // Custom function not part of onesignal api
  setUser = async (user: User) => {
    const instance = await this.instance;

    this.sendTag('first_name', user.first_name);
    this.sendTag('last_name', user.last_name);

    try {
      // We have to remove it first, some odd behavior on IOS...
      await instance.removeExternalUserId();
      this.setExternalUserId(`${user.id}`);
      console.log('Should have fired the setExternal');
    } catch (e) {
      console.log('Failed to set one signal');
      console.log(JSON.stringify(e));
    }
  };
}

export const oneSignalUtil = new OneSignalUtil();
