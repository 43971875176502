import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { UserContext } from '../context/UserContext';
import { isEmailValid } from '../util/isEmailValid';
import { shouldEditEmail } from '../util/shouldEditEmail';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { Button } from './Button/Button';
import { P, Text } from './Text';

export const UpdateEmailModal = () => {
  const { user, updateUser } = useContext(UserContext);
  const [openAlert, setAlert] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const [get, set] = useLocalStorage();

  useEffect(() => {
    const handleOpenModal = async () => {
      const emailModalDismissed = await get('emailModalDismissed');
      const closedBlankSlate = await get('blankSlateClosed');

      // If email is invalid, user has closed BlankSlate, and user receives emailModalDismissed as empty
      if (
        shouldEditEmail(user?.email) &&
        isEmpty(emailModalDismissed) &&
        closedBlankSlate
      ) {
        // we will open the UpdateEmailModal
        setAlert(true);
      }
    };
    if (shouldEditEmail(user.email)) {
      handleOpenModal();
    }
  }, [get, user.email]);

  const handleClose = async (userEmail: string) => {
    setError('');
    // if user closes the modal and email is still not valid then reset value for localStorage - emailModalDismissed
    if (shouldEditEmail(userEmail)) {
      const today = moment().format('MM/DD/YYYY');
      const ttl = {
        duration: 2,
        unit: 'weeks',
        createdAt: today,
      };
      set('emailModalDismissed', today, ttl);
    }
    // close the UpdateEmailModal
    setAlert(false);
  };

  const successOnClose = () => {
    // if email update is successful then we use new email
    handleClose(email);
  };

  const dismissOnClose = () => {
    // if email update did not occur then we use user's email
    handleClose(user.email);
  };

  const handleSubmit = async () => {
    const result = await updateUser({ email });
    if (result.error) {
      // if there is an error from updateUser() then we let the user know there was an error
      setError(result.error.message || t('components.UpdateEmailModal.ERROR'));
    } else if (result.success) {
      successOnClose();
    }
  };

  return (
    <IonModal
      onDidDismiss={dismissOnClose}
      isOpen={openAlert}

      //  swipeToClose
    >
      <IonContent>
        <IonItem style={{ padding: 10, marginTop: 100 }} lines="none">
          <Text style={{ textAlign: 'center', fontWeight: 'bold' }}>
            <P style={{ padding: '18px' }}>
              {t('components.UpdateEmailModal.DIRECTIONS')}
              {/* If there is an error we will inform the user  */}
              {!isEmpty(error) && (
                <P
                  style={{
                    fontWeight: 'normal',
                    color: 'var(--ion-color-danger)',
                  }}
                >
                  {error}
                </P>
              )}
            </P>
          </Text>
        </IonItem>
        <IonItem>
          <IonLabel>{t('pages.settings.EMAIL_ADDRESS')}</IonLabel>
          <IonInput
            // if the email in the input field is invalid then we let the user know
            onBlur={() =>
              setError(
                !isEmailValid(email)
                  ? t('components.UpdateEmailModal.INVALID_EMAIL')
                  : ''
              )
            }
            value={email}
            type="email"
            placeholder={t('pages.settings.EMAIL_ADDRESS')}
            onIonChange={(e) => setEmail(e.detail.value!)}
          />
        </IonItem>

        <IonItem style={{ padding: 10 }} lines="none">
          <Button
            disabled={!isEmailValid(email)}
            slot="end"
            onClick={handleSubmit}
            type="submit"
          >
            {t('common.SAVE')}
          </Button>
          <Button
            slot="start"
            color="light"
            onClick={dismissOnClose}
            type="submit"
          >
            {t('common.SKIP')}
          </Button>
        </IonItem>
      </IonContent>
    </IonModal>
  );
};
