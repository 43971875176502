import React, { useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import styled from '@emotion/styled';
import { IonFooter, IonGrid, IonToolbar } from '@ionic/react';
import { Step } from './Step';

interface StepEntry {
  // Title of the step
  title: string;
  // Element to render in the step, can contain
  // a form, an image, whatever
  element: () => JSX.Element;
}

interface StepperProps {
  id: string;
  steps: StepEntry[];
  currentStep: number;
  goNextStep: () => void;
  goPreviousStep: () => void;
  loggedIn: boolean;
}

const StepperSelector = styled.div`
  align-items: start;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(
    ${(props: { size: number }) => props.size},
    100vw
  );
  position: relative;
  transition: 1s all ease;
`;

export const Stepper: React.FC<StepperProps> = ({
  currentStep,
  goNextStep,
  goPreviousStep,
  loggedIn,
  steps,
}) => {
  const stepperSelector = useRef<HTMLDivElement>(null);
  const moveStepper = debounce(() => {
    if (stepperSelector.current) {
      const stepper = stepperSelector.current;
      stepper.style.transform = `translateX(-${100 * (currentStep - 1)}vw)`;
    }
  }, 0);

  // Every time our currentStep is updated, we are going to trigger this
  useEffect(() => {
    moveStepper();
    // eslint-disable-next-line
  }, [currentStep]);

  return (
    <IonGrid>
      <StepperSelector ref={stepperSelector} size={steps.length}>
        {steps.map(({ element: Component }: StepEntry, i: number) => (
          <Component key={i} />
        ))}
      </StepperSelector>
      <IonFooter style={{ bottom: 0, position: 'fixed', maxHeight: '100px' }}>
        <IonToolbar>
          <Step
            currentStep={currentStep}
            isFirst={currentStep === 1}
            isLast={currentStep === steps.length}
            loggedIn={loggedIn}
            goNextStep={goNextStep}
            goPreviousStep={goPreviousStep}
          />
        </IonToolbar>
      </IonFooter>
    </IonGrid>
  );
};
