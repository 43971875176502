import { IonRouterOutlet, setupIonicReact } from '@ionic/react';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { DeepLink } from './components/DeepLink';
import { Loading } from './pages/Loading';
import { Upgrade } from './pages/Upgrade';

import '@ionic/react/css/ionic-swiper.css';
import 'swiper/css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import { Dashboard } from './pages/Dashboard';
import { ForgotPassword } from './pages/ForgotPassword';
import { Login } from './pages/Login';
import { Purchase } from './pages/Purchase';
import { RedeemLogin } from './pages/RedeemLogin';
import { Signup } from './pages/Signup';
import './theme/custom.css';
import './theme/variables.css';
setupIonicReact({
  mode: 'ios',
});
const App: React.FC = () => {
  return (
    <>
      <DeepLink />

      <IonRouterOutlet>
        <Redirect exact from="/" to="/dashboard" />
        <Route
          path="/dashboard"
          component={(props: any) => (
            <Loading {...props} privateRoute component={Dashboard} />
          )}
        />
        <Route
          path="/purchase"
          component={(props: any) => (
            <Loading {...props} component={Purchase} />
          )}
        />
        <Route
          path="/register/affiliate/:source/:handle"
          component={(props: any) => (
            <Loading privateRoute {...props} component={Dashboard} />
          )}
        />
        <Route
          exact
          path="/redeem/:handle"
          component={(props: any) => (
            <Loading {...props} component={RedeemLogin} />
          )}
        />
        <Route
          path="/upgrade"
          component={(props: any) => <Loading {...props} component={Upgrade} />}
        />
        <Route render={() => <Redirect to={`/`} />} />

        <Route path="/login" component={Login} />
        <Route
          path="/signup"
          component={(props: any) => (
            <Loading
              {...props}
              privateRoute
              component={Dashboard}
              prompt={Signup}
            />
          )}
        />
        <Route
          path="/forgot-password"
          component={(props: any) => (
            <Loading
              {...props}
              privateRoute
              component={Dashboard}
              prompt={ForgotPassword}
            />
          )}
        />
      </IonRouterOutlet>
    </>
  );
};

export default App;
